import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { User } from "../model/user";
import { DataBase } from "../model/dataBase";
import { environment } from "src/environments/environment";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {

  user: User = {};
  erreur = {};
  returnUrl: string;
  returnUrlAdmin: string;
  returnUrlOperateur: string;
  returnUrlAdministrateur: string;
  showHideClass:string ="pi pi-eye";
  inputType:string ="password";
  // labelChoise="تسجيل الدخول";
  labelChoise="Ouvrir Session";
  dataBase: DataBase = {};
  dataBases: DataBase[];
  myUrl: string = `${environment.urlApi}`;
  langueLbl: string ="الفرنسية";  
  francais: boolean = true;
  langueDirection: string ="ltr";
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
  ) {this.erreur="";}

  ngOnInit() {
    this.returnUrl =this.route.snapshot.queryParams["returnUrl"] || "/Accueil";
    this.returnUrlAdministrateur =this.route.snapshot.queryParams["returnUrl"] || "/Accueil";
    this.returnUrlAdmin =this.route.snapshot.queryParams["returnUrl"] || "/Accueil";
    this.returnUrlOperateur =this.route.snapshot.queryParams["returnUrl"] || "/tactile";
    document.body.className = "selector";
    
    this.authenticationService.logout();
    this.database();
    //sanifleurfacture  dbbeblio
  }
  database() {
    this.dataBase.action = "Select";
    this.authenticationService
      .dataBase(this.dataBase)
      .toPromise()
      .then((x: DataBase[]) => (this.dataBases = x,this.setActif()));
  }
  setActif(){
    let rechercheDB =this.dataBases.find((x) => x.actif == true);
    if(rechercheDB){
      this.user.DBase= rechercheDB.data;
      this.user.annee= rechercheDB.designation;
      // console.log(rechercheDB);
    }
  }
  login() {
    let rechercheDB =this.dataBases.find((x) => x.data == this.user.DBase);
    if(rechercheDB){
      this.user.annee= rechercheDB.designation;
    }
    // console.log(this.user);
    this.authenticationService
      .login(this.user.username, this.user.password, this.user.DBase, this.user.annee,this.francais)
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log(data);
          if (data.groupe == "Administrateur" ||data.groupe == "Administrateurs"  ) {
            this.router.navigate([this.returnUrlAdministrateur]);
          } else if (data.groupe == "Admin") {
            this.router.navigate([this.returnUrlAdmin]);
          } else if (data.groupe == "User") {
            this.router.navigate([this.returnUrl]);
          } else if (data.groupe == "Operateur") {
            this.router.navigate([this.returnUrlOperateur]);
          }
        },
        (error) => {
          this.erreur =JSON.stringify(error);
          console.log(this.erreur);
          // alert("Votre Mots de pass ou le nom d'utilisateur est erroné ");
          this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Votre Mots de pass ou le nom d'utilisateur est erroné "});
          //this.error = error;
          //this.loading = false;
        }
      );
  }
  changeLangue(){

    if(this.francais==true){
      this.langueDirection="rtl";
      this.langueLbl="العربية";
      this.francais=false;
      this.labelChoise="تسجيل الدخول";
    }else{
      this.langueDirection="ltr";
      this.langueLbl="الفرنسية";
      this.francais=true;
      this.labelChoise="Ouvrir Session";
    }
    
  } 
  showPassword(){
    if (this.showHideClass == 'pi pi-eye'){
      this.showHideClass = 'pi pi-eye-slash';
      this.inputType='text';
    }else{
      this.showHideClass = 'pi pi-eye';
      this.inputType='password';
    }
  }
  register() {
    this.router.navigate(["/register"]);
  }
}
