import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Type } from "../../model/type";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { Data } from "src/app/model/stockDepot";
import { DataBase } from "src/app/model/dataBase";

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-msqlSchema",
  templateUrl: "msqlSchema.component.html",
  styleUrls: ["msqlSchema.component.scss"],
  providers: [MessageService],
})
export class MsqlSchemaComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  Type: Type = {};
  Types: any[];
  selectedType: Type;
  displayDialog: boolean;
  selectedOption: string;
  file?: string;
  selectedFile = null;  
  Donnee        : DataBase = {};
  Donnees       : DataBase[]; 
  dataTable     : string;
  dataBase: DataBase = {};
  dataBases: DataBase[];
  constructor(
    private authenticationService: AuthenticationService,
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  ngOnInit() {
    this.donnee();
    this.database();
  }

  database() {
    this.dataBase.action = "Select";
    this.authenticationService
      .dataBase(this.dataBase)
      .toPromise()
      .then((x: DataBase[]) => (this.dataBases = x, console.log(x) ));
  }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable = "getSchema";
    this.Donnee.DBase = "dbbiblio";
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: Type[]) => {
        this.Types = x;
        const propertyNames = Object.values(this.Types[0]);
        this.Types= JSON.parse(JSON.stringify(propertyNames)) 
      });
  }
  save() {
    if (this.Type.classement == null) {
      alert("classement est vide - رقم السلسل فارغ");
    } else if (this.Type.designation == null) {
      alert("famille est vide - النوع فارغ");
    } else {
      if (this.Type.id) {
        this.Type.action = "Update";
        this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .favoris(this.Type)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            } else {
              console.log(res);
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.Type.action = "Insert";
        this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .favoris(this.Type)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Type.action = "Delete";
      this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .favoris(this.Type)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Type = {};
    this.file="";
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.file="";
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Type = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.file="";
    this.newDonnee = false;
    if (event.data.images == null) {
      var leet = this.myUrl + "/API/API/uploads/default.png";
    }else{
      var leet = this.myUrl + "/API/API/uploads/images/" + event.data.images;     
    }
    this.imageUrl = leet;
    // alert(leet);
    this.Type = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Type): Type {
    let Type = {};
    for (let prop in c) {
      Type[prop] = c[prop];
    }
    return Type;
  }
}
