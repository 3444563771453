import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./auth.guard";
import { RegisterComponent } from "./register/register.component";
import { GestionUserComponent } from "./gestion-user/gestion-user.component";
import { TypesComponent } from "./article/types/types.component";
import { ArticlesComponent } from "./article/articles/articles.component";
import { RechercherArticleComponent } from "./rechercherArticle/rechercherArticle.component";
import { SousTypesComponent } from "./article/sous-types/sous-types.component";
import { ClientComponent } from "./Ventes/client/client.component";
import { AchatComponent } from "./achats/achat/achat.component";
import { VenteComponent } from "./Ventes/vente/vente.component";
import { PersonnelComponent } from "./Autres/personnel/personnel.component";
import { FournisseursComponent } from "./achats/fournisseurs/fournisseurs.component";
import { StockComponent } from "./stocks/stock/stock.component";
import { SituationVenteComponent } from "./Ventes/situation-vente/situation-vente.component";
import { AccueilComponent } from "./accueil/accueil.component";
import { ModifierFactureComponent } from "./Factures/modifier-facture/modifier-facture.component";
import { ChargesComponent } from "./Autres/charges/charges.component";
import { SituationPaiementComponent } from "./situation-paiement/situation-paiement.component";
import { RendezVousComponent } from "./Autres/rendez-vous/rendez-vous.component";
import { SituationAchatComponent } from "./achats/situation-achat/situation-achat.component";
import { SalaireComponent } from "./salaire/salaire.component";
import { EcheancierComponent } from "./echeancier/echeancier.component";
import { AlertStockComponent } from "./stocks/alert-stock/alert-stock.component";
import { Calendar } from "@fullcalendar/core";
import { CalendarComponent } from "./Autres/calendar/calendar.component";
import { VariationStockComponent } from "./stocks/variation-stock/variation-stock.component";
import { AddStockComponent } from "./stocks/add-stock/add-stock.component";
import { VenteProComponent } from "./Ventes/vente-pro/vente-pro.component";
import { SituationPaiementVenteComponent } from "./Ventes/situation-paiement-vente/situation-paiement-vente.component";
import { SituationPaiementAchatComponent } from './achats/situation-paiement-achat/situation-paiement-achat.component';
import { DevisComponent } from './Ventes/devis/devis.component';
import { FactureComponent } from './Factures/facture/facture.component';
import { SituationPaiementFactureComponent } from './Factures/situation-paiement-facture/situation-paiement-facture.component';
import { CryptComponent } from './crypt/crypt.component';
import { ParametreComponent } from './parametre/parametre.component';
import { StockDepotComponent } from './stocks/stockDepot/stockDepot.component';
import { StockDepotsComponent } from './stocks/stockDepots/stockDepots.component';
import { DepotsComponent } from './stocks/depots/depots.component';
import { AvoirComponent } from './Ventes/avoir/avoir.component';
import { SituationPaiementClientComponent } from "./Ventes/situation-paiement-client/situation-paiement-client.component";
import { InitialStockComponent } from "./stocks/initial-stock/initial-stock.component";
import { SituationPaiementFournisseurComponent } from "./achats/situation-paiement-fournisseur/situation-paiement-fournisseur.component";
import { AvoirsComponent } from "./Ventes/avoirs/avoirs.component";
import { AvoirsAchatComponent } from "./achats/avoirs-achat/avoirs-achat.component";
import { VariationStockDepotComponent } from "./stocks/variation-stock-depot/variation-stock-depot.component";
import { MouvementStockDepotComponent } from "./stocks/mouvement-stock-depot/mouvement-stock-depot.component";
import { ModePaiementComponent } from "./article/mode-paiement/mode-paiement.component";
import { ListesComponent } from "./article/listes/listes.component";
import { ListeDetailComponent } from "./article/listeDetail/listeDetail.component";
import { SituationFactureComponent } from "./Factures/situation-facture/situation-facture.component";
import { SituationStockComponent } from "./Factures/situation-stock/situation-stock.component";
import { BarreCodeComponent } from "./article/barreCode/barreCode.component";
import { TactileComponent } from "./Ventes/tactile/tactile.component";
import { SituationPaiementClientFactureComponent } from "./Factures/situation-paiement-client-facture/situation-paiement-client-facture.component";
import { AvoirFatureComponent } from "./Factures/avoirFacture/avoirFacture.component";
import { DevissComponent } from "./Ventes/Deviss/Deviss.component";
import { CommercialComponent } from "./Ventes/commercial/commercial.component";
import { ArticlesRestoreComponent } from "./article/articlesRestore/articlesRestore.component";
import { AcceuilComponent } from "./acceuil/acceuil.component";
import { ClientFactureComponent } from "./Factures/clientFacture/clientFacture.component";
import { ClientCamionComponent } from "./camion/clientCamion/clientCamion.component";
import { SituationPaiementClientCamionComponent } from "./camion/situation-paiement-clientCamion/situation-paiement-clientCamion.component";
import { AvoirsCamionComponent } from "./camion/avoirsCamion/avoirsCamion.component";
import { VenteProCamionComponent } from "./camion/vente-proCamion/vente-proCamion.component";
import { VariationStockDepotCamionComponent } from "./camion/variation-stock-depotCamion/variation-stock-depotCamion.component";
import { ArticlesCodeBareComponent } from "./article/articlesCodeBare/articlesCodeBare.component";
import { VariationStockDepotJointComponent } from "./stocks/variation-stock-depot-joint/variation-stock-depot-joint.component";
import { controleStockComponent } from "./stocks/controle-stock/controle-stock.component";
import { ClotureComponent } from "./cloture/cloture.component";
import { CodeBarrePrintEtiquettesComponent } from "./etiquettes/codeBarrePrintEtiquettes/codeBarrePrintEtiquettes.component";
import { CodeBarreComponent } from "./etiquettes/codeBarre/codeBarre.component";
import { CodeBarrePrintComponent } from "./etiquettes/codeBarrePrint/codeBarrePrint.component";
import { CodeBarrePrintEtiquetteComponent } from "./etiquettes/codeBarrePrintEtiquette/codeBarrePrintEtiquette.component";
import { FavorisComponent } from "./article/favoris/favoris.component";
import { ArticlesDuplicatComponent } from "./article/articlesDuplicat/articlesDuplicat.component";
import { MsqlSchemaComponent } from "./article/msqlSchema/msqlSchema.component";
// import { TactileAchatComponent } from "./achats/tactileAchat/tactileAchat.component";

const routes: Routes = [
  // {
  //   path: "",
  //   redirectTo: 'main'
  // },
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Accueil",
    component: AccueilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "Depart",
    component: AcceuilComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "GestionUser",
    component: GestionUserComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: "types",
    component: TypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "favoris",
    component: FavorisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "msqlSchema",
    component: MsqlSchemaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "listes",
    component: ListesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "listeDetail",
    component: ListeDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "modePaiement",
    component: ModePaiementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sousTypes",
    component: SousTypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "articles",
    component: ArticlesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "articlesDuplicat",
    component: ArticlesDuplicatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "CodeBarre",
    component: CodeBarreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "articlesCodeBareComponent",
    component: ArticlesCodeBareComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "articlesRestore",
    component: ArticlesRestoreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "RechercherArticle",
    component: RechercherArticleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "CodeBarrePrint",
    component: CodeBarrePrintComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "CodeBarrePrintEtiquette",
    component: CodeBarrePrintEtiquetteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "CodeBarrePrintEtiquettes",
    component: CodeBarrePrintEtiquettesComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "BarreCode",
    component: BarreCodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "controle",
    component: controleStockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cloture",
    component: ClotureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clients",
    component: ClientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientsCamion",
    component: ClientCamionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "commercial",
    component: CommercialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "rendezVous",
    component: RendezVousComponent,
    canActivate: [AuthGuard],
  },
  {
  	path: 'Calendar',
  	component: CalendarComponent,
  	canActivate: [ AuthGuard ]
  },
  {
    path: "achats",
    component: AchatComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "achatsTactile",
  //   component: TactileAchatComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "fournisseurs",
    component: FournisseursComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiement",
    component: SituationPaiementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementAchat",
    component: SituationPaiementAchatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "echeanchier",
    component: EcheancierComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationAchat",
    component: SituationAchatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ventes",
    component: VenteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tactile",
    component: TactileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avoir",
    component: AvoirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avoirs",
    component: AvoirsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avoirsCamion",
    component: AvoirsCamionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avoirFacture",
    component: AvoirFatureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "avoirsAchat",
    component: AvoirsAchatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ventesPro",
    component: VenteProComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ventesProCamion",
    component: VenteProCamionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "facture",
    component: FactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationFacture",
    component: SituationFactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationStockFacture",
    component: SituationStockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementFacture",
    component: SituationPaiementFactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "clientFacture",
    component: ClientFactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "devis",
    component: DevisComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "deviss",
    component: DevissComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementVente",
    component: SituationPaiementVenteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementClient",
    component: SituationPaiementClientComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementClientCamion",
    component: SituationPaiementClientCamionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementClientFacture",
    component: SituationPaiementClientFactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationPaiementFournisseur",
    component: SituationPaiementFournisseurComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "modifierFacture",
    component: ModifierFactureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "situationsVentes",
    component: SituationVenteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stock",
    component: StockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "StockDepot",
    component: StockDepotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "StockDepots",
    component: StockDepotsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "VariationStockDepot",
    component: VariationStockDepotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "VariationStockDepotJoint",
    component: VariationStockDepotJointComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "VariationStockDepotCamion",
    component: VariationStockDepotCamionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "MouvementStockDepot",
    component: MouvementStockDepotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "depots",
    component: DepotsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stockInitial",
    component: InitialStockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stockInitiall",
    component: AddStockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "alertStock",
    component: AlertStockComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "variationStock",
    component: VariationStockComponent,
    // component: VariationStockDepotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "personnels",
    component: PersonnelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "salaire",
    component: SalaireComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "charges",
    component: ChargesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "crypt",
    component: CryptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "parametre",
    component: ParametreComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
