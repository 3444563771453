import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input } from "@angular/core";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import { Article } from "../../model/article";
import { Vente } from "../../model/vente";
import { DecimalPipe, UpperCasePipe } from "@angular/common";
import * as XLSX from "xlsx";
import { Client } from "../../model/client";
import { Achat } from "../../model/achat";
import {Message,MessageService,ConfirmationService} from 'primeng/api';
import { Charge } from '../../model/charge';
import { Paiement } from '../../model/paiement';
import { Depot } from "../../model/depot";
import { ModePaiement } from "src/app/model/modePaiement";
import { HostListener } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';// declare var $:JQueryStatic;
import { User } from "src/app/model/user";
import { Avoir } from "src/app/model/avoir";
import { DataBase } from "src/app/model/dataBase";
// declare var $;
// import {CheckboxModule} from 'primeng/checkbox';
@Component({
  selector: "app-vente-pro",
  templateUrl: "vente-pro.component.html",
  styleUrls: ["vente-pro.component.scss"],
  providers: [DecimalPipe,MessageService,ConfirmationService],
})
export class VenteProComponent implements OnInit {
  // @Input() francais: boolean ;
  // @Input() langueDirection: string ;
  // @Input() langueLbl: string ;  
  
  @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete ;
  @ViewChild('autoCompleteCodeBarre') private autoCompleteCodeBarre: AutoComplete ;
  key:string;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var activeElement = document.activeElement;
    var inputs = ['input', 'select', 'button', 'textarea'];

    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
      
    }else{
      this.key += event.key;
      if(this.key.includes("Enter")){
        this.key = this.key.replace("Enter", "");
        this.key = this.key.replace("undefined", "");
        // alert(this.key);
        this.addArticleBarCode(this.key,'codeBarre');
        this.key="";
      }  
    }
  }
  msgs1: Message[];    
  msgs2: Message[];

  idArticle: number;
  nbre:number;
  higthCol:string;
  idArticleTest: number;
  venteX: number;
  Article: Article = {};
  Articles: Article[];
  ArticlesRecherche: Article[];
  tableDetailVentess: Vente[]; 
  Donnees     : DataBase [];
  Donnee      : DataBase = {};
  historyPrice: string = "" ;  
  Familles: any = [];
  stock: Article[];
  Achat: Achat = {};
  // Achats: Achat[];
  rechercheArticle: Article = {};
  rechercheArticles: Article[];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  selectedArticle:string;
  tableVentes: Vente[];
  //----------Paiement Recap----------//
    tableVentess: Vente[];
    tableRecapPaiements: Paiement [];
    tableRecapSoldeInitial: Paiement [];
    tableRecapAvoirs: Avoir[];
    tableRecapAvoirAchats: Avoir[];
    tableRecapAchats: Achat[];
    Avoirs: Avoir [];
    Avoir: Avoir = {};
    reste:number;
  //----------Paiement Recap----------//

  tableDetailVentes: Vente[];
  artilcesClient: Vente[];
  artilceClient: Vente={};
  Users                : User[];
  User                 : User={};
  Revendeurs           : User[];
  Revendeur            : User={};
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requettesCommandeVentes: any = [];
  tableAvance: any = {};
  tableAvances: any = [];
  tableClient: any = {};
  tableArticlestock: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];  
  ClientsBon: Vente[];
  Client: Client = {};
  ClientF: Client = {};
  Clients: Client[]; 
  action   : string='';
  dataTable   : string;
  displayDialog: boolean;
  desablePrint: boolean ;
  showButon: boolean;
  showButonSauvgarder: boolean;
  showButonAvance: boolean = false;
  showButonClient: boolean = false;
  blockedDocument: boolean;
  blockedcalculator: boolean= false;
  block: boolean= false;
  designation: string='';
  quantite: number=0;
  designationSearch: string='';
  nomComplet:string='';
  revendeur:string='';
  nbon:string='';
  numm:string='';
  nomAr:string='';
  nom:string;
  nomDepot:string;
  totalMontant: number;
  totalMarge: number;
  totalQte: number;
  total: number;
  remise: number;
  margeTotal: number;
  idClient: number;
  quantiteVenteGros: number;
  quantiteVenteDetail: number;
  prixVenteGros: number;
  prixVenteDetail: number;
  prixVente1: number;
  prixAchat: number;
  remiseTaux: number;
  idDepotVente: number;
  Exclut:string;  
  index: any;
  colorInput: string;
  indexAvance: string;
  commandeDetail: string;
  commandePaiement: string;
  commandeDetailDepot: string;
  commandeDetailCamion: string;
  prixArticle: string;
  dateDebut: any;
  dateFin: any;
  dateSituationDebut: string;
  dateSituationFin: string;
  msgs: Message[] = [];
  position: string;
  displaybarcode: boolean;
  displayCharge: boolean;
  displayModePaiement: boolean;
  displayDialogClient: boolean;
  afficher: boolean;
  BarCode:string;
  tableDepot: Depot = {};
  Depot: Depot = {};
  Depots: Depot[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  depots: string = "Depots" ;
  able: boolean =false;
  Global: boolean =false;
  AdminAble: boolean =false;
  depotActivate: boolean =false;
  dropdownArticle: boolean =false;
  switch: boolean =true;
  afficherCodebarre: boolean =false;
  myUrl: string = `${environment.urlApi}`;
  francais: boolean =false;
  langueDirection: string ="ltr";
  langueLbl: string ="العربية";
  edited:boolean=false;
	Charge: any = {};
  Charges: any[];
  totalCharge:number;
  totalAvoir:number;
  totalAvance:number;
  nbreBon:number;
  colspan:number;
  Paiement: any = {};
  Paiements: any[];
  totalPaiement:number;
  tableDetailPaiements: Paiement[];
  Paiementss: Paiement[];
  filteredArticles: any[];
  filteredCodeBare: any[];
  filteredReference: any[];
	currentNumber = '0';
	firstOperand = null;
	styleOBJ : any;
	operator = null;
	waitForSecondNumber = false;

	public getNumber(v: string){
		// console.log(v);
		if(this.waitForSecondNumber){
        this.currentNumber = v;
        this.waitForSecondNumber = false;
        
      }else{
        this.currentNumber === '0'? this.currentNumber = v: this.currentNumber += v;
      
      }
		//  this.total = this.currentNumber * this.vente.prixVente;
	  }
	
	  public clear(){
      this.currentNumber = '0';
      this.firstOperand = null;
      this.operator = null;
      this.waitForSecondNumber = false;
      }  
      public ok(){
        // alert(this.currentNumber);
        let nbre = parseInt(this.currentNumber);
        var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == this.idArticle);
        this.tableCommandeVentes[index].quantite=nbre;
        this.tableCommandeVentes[index].total = this.tableCommandeVentes[index].prixVente*this.tableCommandeVentes[index].quantite;
        this.totalQantite();
        this.prepareCommandeDetail();
        this.tableCommandeVente = {};
        this.selectedArticle="";
      this.blockedcalculator= false;
      }
  tableData = [];
  @ViewChild('dataTable', {static: true}) table;
  title = 'Example of Angular 8 DataTable';
  dtOptions: DataTables.Settings = {};

  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  addSingle() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}

  ngOnInit() {
    // console.log(window.history.state);
    this.styleOBJ = {'background-image':'linear-gradient(to bottom,#007AD9,#007AD0, white)','margin': '10px', 'display':'inline-block'};
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Admin' ){
      this.AdminAble=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Global' ){
      this.Global=true;
    }
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
    }
    if(this.AuthenticationService.currentUserValue.francais){
      this.francais=false;
      this.langueDirection="ltr"
      this.langueLbl="العربية"
    }else{
      this.francais=true;
      this.langueDirection="rtl"
      this.langueLbl="الفرنسية"
    }
    this.Vente.cloture==false;
    this.dropdownArticle=true;
    this.commandeDetail="";
    this.msgs1 = [
      {severity:'success', summary:'Success', detail:'Message Content'},
      {severity:'info', summary:'Info', detail:'Message Content'},
      {severity:'warn', summary:'Warning', detail:'Message Content'},
      {severity:'error', summary:'Error', detail:'Message Content'}
     ];
    this.dateSituationDebut = new Date().toISOString().split("T")[0];
    this.dateSituationFin = new Date().toISOString().split("T")[0];
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
    if(window.history.state.idCommande){
      this.numm=window.history.state.idCommande;
      this.dateSituationDebut=window.history.state.dateCommande;
      this.dateSituationFin=window.history.state.dateCommande;
      this.rechercher();
    }
    this.article();
    this.getArticles();
    // this.achat();
    this.revendeurs()
    this.getDataTable();
    this.client();
    // this.clientNbreBon();
    this.depot();
    this.modePaiement();
    this.venteDetail();
    this.paiementDetail();
    // this.ventes();

    this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Vente.nom="";
    this.depots="Ventes";
    if(!this.able && !this.depotActivate){this.colspan=4;
    }else if(!this.able && this.depotActivate){this.colspan=3;
    }else if(this.able && !this.depotActivate){this.colspan=5;
    }else if(this.able && this.depotActivate){this.colspan=4;}
    this.updatePageDimensions();
    this.donnee();
  }

  @HostListener('window:resize')
  onResize() {
    this.updatePageDimensions();
  }
  
  pageHeight: number;
  divHeight: string;
  updatePageDimensions() {
    this.pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
         // Output the values to the console
    console.log('Page Height:', this.pageHeight);
    if(this.Vente.cloture==true){
      this.divHeight=(this.pageHeight-150)+'px';
    }else{
      this.divHeight=(this.pageHeight-350)+'px';
    }    
  }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable = "database";
    this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x));
  }
//Debut ------------------------------recap paiement Client -----------------------
ventes() {
  this.Vente.action = "RecapByClient";
  this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .recapClient(this.Vente)
    .toPromise()
    .then(
      (x: Vente[]) => (this.tableVentess = x, this.paiementRecapp())
    );
}
paiementRecapp() {
  this.Paiement.action = "Recap";
  this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .paiementClient(this.Paiement)
    .toPromise()
    .then((x: Paiement[]) => (
      this.tableRecapPaiements = x,
      this.soldesInitial()));
}
soldesInitial() {
  this.Paiement.action = "soldeInitial";
  this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .paiementClient(this.Paiement)
    .toPromise()
    .then((x: Paiement[]) => (
      this.tableRecapSoldeInitial = x,
      // console.log(x),
      this.avoirRecap()));
}
avoirRecap() {
  this.Avoir.action = "SelectRecapByIdClient";
  this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .avoir(this.Avoir)
    .toPromise()
    .then((x: Avoir[]) => (
      this.tableRecapAvoirs = x,
      this.avoirAchatRecap()));
}
avoirAchatRecap() {
  this.Avoir.action = "SelectRecapByIdFournisseurClient";
  this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .avoirAchat(this.Avoir)
    .toPromise()
    .then((x: Avoir[]) => (
      this.tableRecapAvoirAchats = x,
      // console.log(x),
      this.achatRecap()));
}
achatRecap() {
  this.Achat.action = "SelectRecapByIdClient";
  this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .recapFournisseur(this.Achat)
    .toPromise()
    .then((x: Achat[]) => (
      this.tableRecapAchats = x,
      // console.log(x),
      this.tablePaiement()));
}
tablePaiement(){
  if (this.tableRecapPaiements.length!=undefined){
    // console.log(this.tableRecapPaiements);
    if(this.tableVentess.length>0){
      this.tableVentess.forEach((row ,index )=>{
        let updateItem = this.tableRecapPaiements.find(item=>item.idClient==row.idClient);
        // console.log(updateItem);
        if (updateItem===undefined){
          this.tableVentess[index].avance = 0;
          this.tableVentess[index].reste = this.tableVentess[index].total;  
        }else{
            this.tableVentess[index].avance = updateItem.montant;
            this.tableVentess[index].reste = this.tableVentess[index].total-updateItem.montant;
        }
      });      
    }
  } 
  this.tableSoldeInitial();
}
tableSoldeInitial(){
  if (this.tableRecapSoldeInitial.length!=undefined){
    if(this.tableVentess.length>0){
      this.tableVentess.forEach((row ,index )=>{
        let updateItem = this.tableRecapSoldeInitial.find(item=>item.idClient==row.idClient);
        if (updateItem===undefined){
          this.tableVentess[index].soldeInitial = 0; 
        }else{
        this.tableVentess[index].soldeInitial = updateItem.montant;
        this.tableVentess[index].reste += -updateItem.montant;      
        }
      });       
    }

  }
  this.tableAvoir();
}
tableAvoir(){
  if (this.tableRecapAvoirs.length!=undefined){
    if(this.tableVentess.length>0){
      this.tableVentess.forEach((row ,index )=>{
        let updateItem = this.tableRecapAvoirs.find(item=>item.idClient==row.idClient);
        if (updateItem===undefined){
          this.tableVentess[index].avoir = 0;
          // this.tableVentes[index].reste += -updateItem.montant;  
        }else{
        this.tableVentess[index].avoir = updateItem.montant;
        this.tableVentess[index].reste += -updateItem.montant;      
        }
      });       
    }

  }
  this.tableAvoirAchat();
}
tableAvoirAchat(){
  if (this.tableRecapAvoirAchats){
    // console.log(this.tableRecapAvoirAchats);
    if(this.tableVentess.length>0){
      this.tableVentess.forEach((row ,index )=>{
        let updateItem = this.tableRecapAvoirAchats.find(item=>item.idClient==row.idClient);
        // console.log(updateItem);
        if (updateItem===undefined){
          this.tableVentess[index].avoirAchat = 0;
        }else{
        this.tableVentess[index].avoirAchat = updateItem.montant;
        this.tableVentess[index].reste += updateItem.montant;      
        }
      });      
    }
 
  }else{
    this.tableVentess.forEach((row ,index )=>{
      this.tableVentess[index].avoirAchat = 0;
    });
  }
  this.tableAchat();
}
tableAchat(){
  if (this.tableRecapAchats.length!=undefined){
    if(this.tableVentess.length>0){
      this.tableVentess.forEach((row ,index )=>{
        let updateItem = this.tableRecapAchats.find(item=>item.idClient==row.idClient);
        // console.log(updateItem);
        if (updateItem===undefined){
          this.tableVentess[index].achat = 0;
        }else{
        this.tableVentess[index].achat = updateItem.total;
        this.tableVentess[index].reste += -updateItem.total;      
        }
      });       
    }

      // console.log(this.tableVentess);
  }
  // 
}
//FIN   ------------------------------recap paiement Client -----------------------

  searchArticle(){
    let filtered = this.Articles.filter((x) => 
      x.designation.toString().toLocaleLowerCase().includes(this.designationSearch.toLocaleLowerCase())
    );   
    this.filteredArticles = filtered;
  }
  getArticles() {
		// this.Article.action = 'Select';
		// this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
    this.blockedDocument=true;
		this.Article.action = 'SelectRecapDepot';
    this.Article.depot  = 'magasin';
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.data.stockDepot(this.Article).toPromise().then((x: Article[]) => (this.stock = x,this.blockedDocument=false));
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  filterCodeBare(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredCodeBare = filtered;
  }
  filterReference(event) {
    let filtered : any[] = [];
    let query = event.query;
    filtered = this.Articles.filter((x) => x.reference.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredReference = filtered;
  }

  showDialogCharge(){    
    this.displayCharge=true;
  }
  rechercher(){
    this.blockedDocument=true;
    // this.paiement(this.dateSituationDebut,this.dateSituationFin);
    this.venteDetail();
  }
  paiement(dateDebut,dateFin) {
    this.Paiementss =[];
		// this.Paiement.action = 'FilterPaiementCaisse';
		this.Paiement.action = 'PaiementByDate'; 
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.Paiement.dateDebut = dateDebut;
		this.Paiement.dateFin   = dateFin;
		this.data
			.paiementClient(this.Paiement)
			.toPromise()
			.then(
				(x: Paiement[]) => (this.Paiementss = x, this.avoir(this.dateSituationDebut,this.dateSituationFin))
			);
  }
  avoir(dateDebut,dateFin) {
    this.Avoirs =[];
		this.Avoir.action = 'FilterAvoirCaisse';
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.Avoir.dateDebut = dateDebut;
		this.Avoir.dateFin   = dateFin;
		this.data
			.avoir(this.Avoir)
			.toPromise()
			.then(
				(x: Avoir[]) => (this.Avoirs = x, this.charge(this.dateSituationDebut,this.dateSituationFin))
			);
  }

  charge(dateDebut,dateFin) {
    this.Charges =[];
		this.Charge.action = 'FilterChargeCaisse';
    this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.Charge.dateDebut = dateDebut;
		this.Charge.dateFin   = dateFin;
		this.data
			.charge(this.Charge)
			.toPromise()
			.then(
				(x: Charge[]) => (this.Charges = x,this.calculTotalCharge())
			);
  }

  calculTotalCharge(){
    this.totalCharge = 0;
    
    if(this.Avoirs!= undefined){
      if(this.Charges.length>0){
        this.totalCharge = this.Charges
          .map((t) => t.montant * 1)
          .reduce((a, value) => a + value, 0);  
      }      
    }
    this.calculTotalAvoir();
  }

  calculTotalAvoir(){
        this.totalAvoir = 0;
      // console.log(this.Avoirs);
      if(this.Avoirs!= undefined){
        if(this.Avoirs.length>0){      
          this.totalAvoir = this.Avoirs
            .map((t) => t.montant * 1)
            .reduce((a, value) => a + value, 0);  
        }
      }
  }  
  showDialogModePaiement(){
    this.displayModePaiement=true;
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
    this.tableAvance.idModePaiement=1;
  }
  getDataTable() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data.getData(this.Article).subscribe(data => {
      // console.log(data);
      this.tableData = data.data;
      this.dtOptions = {
        data: this.tableData,
        columns: [
          {title: 'ID', data: 'id'},
          {title: 'Désignation', data: 'label'},
        ]
      };
    });
  }

  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = x,
        this.filteredArticles = x,
        this.ArticlesRecherche = x,
        this.famille()
        ));
  }
  famille(){
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    let datatable="type";
    this.data
      .donnee(datatable,this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.Familles = x, this.generateArticle()));    
  }
  generateArticle(){
    this.Articles.forEach((row,index)=>{
      if(!row.codeBarre) {this.Articles[index].codeBarre=""};
      if(!row.reference) {this.Articles[index].reference=""};
    });
  }

  // achat() {
  //   this.Achat.action = "Select";
  //   this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   this.data
  //     .achat(this.Achat)
  //     .toPromise()
  //     .then((x: Achat[]) => (this.Achats = x));
  // }
  paiementDetail() {
    this.Paiement.action = "paiemenetParIdCommande";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.tableDetailPaiements = x,this.calculTotalAvance()));
  }
  // getPaiement() {
	// 	this.Paiement.action = 'paiemenetParIdCommande';
	// 	this.data.paiementClient(this.Paiement).toPromise().then((x: Paiement[]) => (this.recherchePaimentIdCommandes = x,console.log(x)));
  // }
  onEnter() {}
  rechercheDesignation(idArticle) {
    // this.rechercheArticle = this.Achats.find((x) => x.idArticle === idArticle);
    // this.tableCommandeVente.designation = this.rechercheArticle.designation;
  }
  testPrix(qte){
    // alert(this.quantiteVenteGros +" | "+this.prixVenteDetail)
    var Qte = parseInt(qte);
    // if(Qte>=this.quantiteVenteGros){
    //   this.tableCommandeVente.prixVente=this.prixVenteGros;
    // }else if(Qte>=this.quantiteVenteDetail){
    //   this.tableCommandeVente.prixVente=this.prixVenteDetail;
    // }else{
    //   this.tableCommandeVente.prixVente=this.prixVente1;
    // }
    this.tableCommandeVente.prixVente=this.prixVente1;
  }
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
    if(this.Vente.cloture==false){
      // this.setFocusDesignation();
      this.autoCompleteObject.focusInput();
    }
  }
  findPrixByDataBase() {
  // console.log(this.Vente.idClient + this.Vente.DataBase + this.tableCommandeVente.idArticle);
    
    if(this.Vente.idClient && this.Vente.DataBase && this.tableCommandeVente.idArticle){
      this.tableDetailVentess=[];
      this.Vente.idArticle=this.tableCommandeVente.idArticle;
      this.Vente.action = "SelectDetailParClientArticle";
      this.Vente.DBase  = this.Vente.DataBase;
      // console.log( this.Vente);
      this.data
        .vente(this.Vente)
        .toPromise()
        .then((x: Vente[]) => {
            this.tableDetailVentess = x;
            this.historyPrice='' ;
            this.venteX = 0;
            this.tableDetailVentess.forEach((row)=>{
              // this.historyPrice += row.dateCommande + '-->'+ row.prixVente;
              this.venteX = row.prixVente;
              // console.log("venteX" + this.venteX);
            }); 
          }
          );
    }else{
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Choisir un client ET un article"});
    }
  }
  findPrix(idArticle,colone) {
      // console.log(idArticle,colone);
      // alert(typeof idArticle);
      // alert(colone);
      // console.log(this.stock);    
    if (idArticle!=undefined || this.tableCommandeVente.autocompleteCodeBarre!=undefined){
      this.dropdownArticle=false;

      if(typeof idArticle =="string" && colone=="codeBarre-"){
        idArticle=this.tableCommandeVente.autocomplete;
        // console.log(this.tableCommandeVente);
        colone="codeBarre";        
      }else{    
        colone="codeBarre-";
      }
      // console.log(idArticle,colone);
      this.index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == idArticle.id
      );
      // alert(this.index);
      if(this.index!=-1){
        this.tableCommandeVente=this.tableCommandeVentes[this.index];
        this.showButon=true;
      }else{
        this.tableCommandeVente.stock             = 0;
        this.tableCommandeVente.idArticle         = idArticle.id; 
        this.tableCommandeVente.designation       = idArticle.designation;
        this.tableCommandeVente.prixAchat         = idArticle.prixAchat;
        this.tableCommandeVente.prixVenteGros     = idArticle.prixVenteGros;
        this.tableCommandeVente.prixVenteDetail   = idArticle.prixVenteDetail;
        this.tableCommandeVente.prixVente         = idArticle.prixVente;
      }
      
        this.tableCommandeVente.remise            = 0;
        this.remiseTaux                           = idArticle.remise;
        this.prixAchat                            = idArticle.prixAchat;
        this.prixVenteGros                        = idArticle.prixVenteGros;
        this.prixVenteDetail                      = idArticle.prixVenteDetail;
        this.quantiteVenteGros                    = idArticle.quantiteVenteGros;
        this.quantiteVenteDetail                  = idArticle.quantiteVenteDetail;
        this.prixVente1                           = idArticle.prixVente;  
      if (idArticle && this.idArticleTest!=idArticle){
        if (this.idArticleTest!=idArticle){
          this.idArticleTest=idArticle;
          this.setFocusQte();
        }
      }

        // console.log(this.tableCommandeVente);
      if(colone=="codeBarre"){
        this.rechercheArticle = this.stock.find((x) => x.codeBarre == idArticle);
        this.tableCommandeVente.autocomplete = this.rechercheArticle; 
      }else if(colone=="designation"){
        this.rechercheArticle = this.stock.find((x) => x.designation == idArticle.designation);
        this.tableCommandeVente.autocomplete = idArticle; 
      }else {
        // console.log(this.stock);
        this.rechercheArticle = this.stock.find((x) => x.id === idArticle.idArticle*1);
        this.tableCommandeVente.autocomplete = idArticle; 
      }
        // console.log(this.tableArticlestock) ;
        if(this.rechercheArticle){
          let rechercheArticleStock = this.stock.filter((x) => x.id == this.rechercheArticle.id);
          this.tableArticlestock = rechercheArticleStock;

          this.tableCommandeVente.stock = this.tableArticlestock.map((t) => (t.stockTotal))
          .reduce((a, value) => a + value, 0);
        }else{
          this.tableCommandeVente.stock =0;
        }

        let idArtic=this.artilcesClient.filter((x) => x.idArticle == this.tableCommandeVente.idArticle);
        
        if(typeof idArtic !== 'undefined' && idArtic.length > 0){
          let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
          this.artilceClient = idArtic.find((x) => x.id == idPrixVentX); 
          this.venteX = this.artilceClient.prixVente;     
        }else{
          this.venteX = 0;
        }
        // console.log(this.venteX);
    }
  }
  client() {
    this.Client.action = "Select";
    this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .client(this.Client)
      .toPromise()
      .then((x: Client[]) => (this.Clients = x,this.clientNbreBon()))
  }
  clientNbreBon(){
    this.Vente.action = "SelectClient";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .vente(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.ClientsBon = x,this.exclut("1")));
  }
  // users() {
  //   this.User.action = "Select";    
  //   this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
  //   console.log(this.User);    
  //   this.data
  //     .donnee('user',this.User)
  //     .toPromise()
  //     .then((x: User[]) => (
  //       this.Users = x,console.log(x),
  //        this.exclut("1")));
  // }
  revendeurs() {
    this.Revendeur.action = "Select";
    this.Revendeur.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee('commercial',this.Revendeur)
      .toPromise()
      .then((x: User[]) => (this.Revendeurs = x));
  }
  exclut(idClient){
    if(idClient){
      this.prepareCommandeDetail();
      if(this.ClientsBon){
        let rechercheNbre = this.ClientsBon.find((x) => (x.idClient == idClient));
        if(rechercheNbre){
            this.nbreBon =rechercheNbre.Nbre;
        }else{
            this.nbreBon = 0;
        }                
      }else{
        this.nbreBon = 0;
      }
      // console.log(this.Clients);
      let recherchee = this.Clients.find((x) => x.id == idClient);
      if(recherchee){
        this.ClientF        = recherchee;
        this.Exclut         = recherchee.exclut;
        this.nomDepot       = recherchee.nom;
        this.Vente.nom      = recherchee.nom;
        this.Vente.adresse  = recherchee.adresse;
        this.Vente.ville    = recherchee.ville;
        this.Vente.plafond  = recherchee.plafond;
      }
      // alert(this.nomDepot);
      // console.log(this.Depots);
      let rechercheDepot = this.Depots.find((x) => x.designation == this.nomDepot);
      this.tableDepot = rechercheDepot;
      if(this.tableDepot){
        this.idDepotVente=this.tableDepot.idDepotVente
      }else{
        this.idDepotVente=3;
      }

      // let rechercheUser = this.Users.find((x) => x.id == this.AuthenticationService.currentUserValue.id);
      // if(rechercheUser){
        // this.Vente.abreviation  = rechercheUser.abreviation;
      // }
      
      // alert(this.idDepotVente);
      if(this.tableDetailVentes){
        this.artilcesClient = this.tableDetailVentes.filter((x) => x.idClient == idClient);
      }
      // console.log(this.tableVentess);
      if(this.tableVentess){
        let recherche = this.tableVentess.find((x) => (x.idClient == idClient));
        if(recherche){
          this.reste=recherche.reste;
        } 
      }
      
    }
  }
  venteDetail() {
    this.Vente.action = "SelectDetail";
    this.Vente.depot = 'magasin';
		this.Vente.dateDebut = this.dateSituationDebut;
		this.Vente.dateFin   = this.dateSituationFin;
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .vente(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.tableDetailVentes = x, this.vente()));
  }
  
  vente() {
    // if ( this.depots=="Ventes"){
    //   this.depots="Depots"
    //   this.Vente.idDepot = " = 0";
    // }else if ( this.depots=="Depots"){
    //   this.depots="Ventes"
    //   this.Vente.idDepot = " <> 0"; 
    // }
    // this.depots="Depots";
    // console.log( this.Vente);
    // if(this.able || this.AuthenticationService.currentUserValue.groupe=='Paiement'){
      this.Vente.action     = "Select";
      this.Vente.depot      = "magasin";
      this.Vente.DBase      = this.AuthenticationService.currentUserValue.DBase;
      this.Vente.dateDebut  = this.dateSituationDebut;
      this.Vente.dateFin    = this.dateSituationFin;
    // }else{
    //   this.Vente.action = "SelectVenteUser";
    //   this.Vente.depot  = "magasin";
    //   this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    //   this.Vente.idUser = this.AuthenticationService.currentUserValue.id;
    // }    
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          this.Ventes = x,
          this.tableVentes = x,
          // console.log(x),
          this.filterCommande(this.dateSituationDebut,this.dateSituationFin)
        )
      );
  }
  filterCommande(dateDebut,dateFin){
    if (this.nom==undefined){this.nom='';}
    if (this.nomComplet==undefined){this.nomComplet='';}
    if (this.revendeur==undefined){this.revendeur='';}
    if (this.nbon==undefined){this.nbon='';}
    if (this.numm==undefined){this.numm='';}
    if (this.nomAr==undefined){this.nomAr='';}
    // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.nomComplet+ 
    // " / " + this.nbon+   " / " + this.numm)
    if (this.Ventes){
      let recherche = this.Ventes.filter((x) => 
       (x.dateCommande >= dateDebut && x.dateCommande <= dateFin) && 
        x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
        x.nomAr.toLocaleLowerCase().includes(this.nomAr.toLocaleLowerCase()) &&
        x.nomComplet.toLocaleLowerCase().includes(this.nomComplet.toLocaleLowerCase()) &&
        x.revendeur.toLocaleLowerCase().includes(this.revendeur.toLocaleLowerCase()) &&
        x.nbon.toString().includes(this.nbon) && 
        x.numm.toString().includes(this.numm)
        );
      // a revoir  
      this.tableVentes = recherche; 
      if(recherche!= undefined){
        this.paiement(dateDebut,dateFin);
        this.paiementRecap(dateDebut,dateFin);
        // this.recherhceMarge();
        this.totalCommande();
      }
    }

      // let recherche = this.Ventes.filter((x) => (
      //   x.dateCommande >= dateDebut && x.dateCommande <= dateFin) 
      //   );
      // if (recherche){
      //   this.tableVentes = recherche; 
      //   this.totalCommande();
      //   this.charge(dateDebut,dateFin);
      //   this.paiementRecap(dateDebut,dateFin);
      // }
  }
  totalCommande() {
    this.total = 0;
    this.total = this.tableVentes.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
    this.remise = 0;
    this.remise = this.tableVentes.map((t) => t.remise * 1).reduce((a, value) => a + value, 0);
    this.margeTotal = 0;
    this.margeTotal = this.tableVentes.map((t) => t.marge * 1).reduce((a, value) => a + value, 0);
    this.blockedDocument=false;
  }
  switchCodeBarre(){
    // console.log('RED');    
    if(this.switch==true){
      this.autoCompleteObject.focusInput();
    }else{
      this.autoCompleteCodeBarre.focusInput();
    }
  }

  depot() {
    this.Depot.action = "SelectDepot";
    this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .depot(this.Depot)
      .toPromise()
      .then((x: Depot[]) => (this.Depots = x));
  }
  findDepot(idDepot) {
    let rechercheDepot = this.Depots.find((x) => x.id == idDepot);
    // this.tableDepot = rechercheDepot;
    this.tableCommandeVente.depot = rechercheDepot.designation;
    // this.idDepotVente=this.tableDepot.idDepotVente
  }
  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.tableAvance.modePaiement = rechercheModePaiement.modePaiement;
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }
  
  calculTotalAvance(){
    this.totalAvance = 0;    
    if(this.tableAvances.length>0){
      this.totalAvance = this.tableAvances
        .map((t) => t.montant * 1)
        .reduce((a, value) => a + value, 0);  
    };
    this.prepareCommandeDetail();
  }
  paiementRecap(dateDebut,dateFin) {
    this.Paiement.action = "RecapByDate";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.Paiement.dateDebut = dateDebut;
		this.Paiement.dateFin   = dateFin;  
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (this.Paiements = x,this.calculTotalPaiement()));
  }
  calculTotalPaiement() {
    this.totalPaiement = 0;    
    if(this.Paiements.length>0){
      this.totalPaiement = this.Paiements
        .map((t) => t.montant * 1)
        .reduce((a, value) => a + value, 0);  
    }  
  }

  // this.recherhceMarge()
  figer(){
    if (this.afficher!=true){
      this.afficher=true;
    }else{
      this.afficher=false;
    }
  }
  cloture(idCommande,cloture) {
    // alert(idCommande+" | "+cloture);
    let etat;
    if (cloture==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.tableCommandeVente.action = "UpdateCloure";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeVente.etat = etat;
      this.tableCommandeVente.id = idCommande;
      this.data
        .vente(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            // this.vente();
            this.venteDetail();
          } else {
            alert(res.message);
          }
        });
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  // recherhceMarge(){
  //       this.tableVentes.forEach((row)=>{
  //       let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);
  //       let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
  //       let index = this.tableVentes.indexOf(updateItem);
  //       let marge =  JSON.parse(JSON.stringify(recherche))
  //         .map((t) => t.marge)
  //         .reduce((a, value) => a + value, 0);
  //       this.tableVentes[index].marge = marge;
  //     }); 
  //     this.totalCommande();
  // }
  saveCommandeVente() {
    this.tableCommandeVente.action = "Insert";
    this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.tableCommandeVente.commandeDetail = this.commandeDetail;
   
    this.data
      .vente(this.tableCommandeVente)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.vente();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
  //   filter(data, colone) {
  //     console.log(data + colone);
  //     let recherche = this.Achats.filter(
  //       (x) =>
  //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
  //     );
  //     this.tableAchats = recherche;
  //     this.total = this.tableAchats
  //       .map((t) => t.montant * 1)
  //       .reduce((a, value) => a + value, 0);
  //   }
  filter(data, colone) {
    let recherche;
    if (colone=="nbon" ){
       recherche = this.Ventes.filter((x) => x.nbon.toString().includes(data));
    }else if ( colone =="numm"){
      recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    ); 
    }
    if (recherche!=undefined){
    this.tableVentes = recherche;
    }
    this.totalCommande();
  }
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  @ViewChild('input1', {static: false}) inputEl:ElementRef;

  barcode(){
    this.displaybarcode=true;
    this.close();
    // this.Vente.nbon='0';
    // this.Vente.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }

  codebarre(){
    this.close();
    if (this.afficherCodebarre==true){
      this.afficherCodebarre=false;
      this.displaybarcode=false;
    }else{
      this.afficherCodebarre=true;
      this.displaybarcode=true;
    }
    // this.Vente.idClient='1';
    let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase()  == "COMPTOIR");
    if(rechercheClient){
      this.Vente.idClient = rechercheClient.id;
    }
    this.tableCommandeVente.idDepot=1;
    this.setFocus();
    // this.displaybarcode=false;
  }
  setFocus(){
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
  @ViewChild('inputCodeBarre', {static: false}) inputCodeBarre:ElementRef;
  setFocusCodeBarre(){
    setTimeout(() => this.inputCodeBarre.nativeElement.focus());
  }  
  @ViewChild('inputArticle', {static: false}) inputArticle:ElementRef;
  setFocusArticle(){
    // setTimeout(() => this.inputArticle.nativeElement.focus());
  }  
  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  @ViewChild('inputAvance', {static: false}) inputAvance:ElementRef;
  setFocusAvance(){
    setTimeout(() => this.inputAvance.nativeElement.focus());
  }
  @ViewChild('printBtn') printBtn: ElementRef<HTMLElement>;
  barcodeHide(){
    this.displaybarcode=false;
  }
  factureSelect(event){
    this.displayDialog = false; 
    this.displaybarcode=true;       
    this.Vente.id = event.idCommande;
    this.Vente.num = event.num;
    this.Vente.numm = event.numm;
    this.Vente.dateCommandee = event.dateCommande;
    this.Vente.idClient = JSON.stringify(event.idClient);
    this.Vente.remise = event.remise;
    this.Vente.nom = event.nom;
    this.Vente.nbon = event.nbon;
    this.Vente.idUser = event.idUser;
    this.Vente.nomComplet = event.nomComplet;
    this.Vente.cloture = event.cloture;
    // alert (this.Vente.idClient);
    let recherche = this.tableDetailVentes.filter(
      (x) => x.id == event.idCommande
    );
    this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
    this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
    this.totalQantite();
    this.prepareCommandeDetail();
    this.setFocus();
    this.afficherCodebarre=true;
    this.displaybarcode=true;
  }
  save() {
    this.action='save';
    this.prepareCommandeDetail();
    if (this.Vente.nbon=='0' ){
      this.Vente.nbon=this.Vente.nbon.toString();
    }
    // console.log('idclient' + this.Vente.idClient);
    // console.log('nbon' + this.Vente.nbon);
    // console.log('commandeDetail' + this.commandeDetail);
    if (this.Vente.idClient && this.commandeDetail!='') {
    this.blockedDocument=true;
    if (this.Vente.id) {
      // alert('update');
      this.tableCommandeVente.action = "Update";      
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      if (this.Vente.idUser){
        this.tableCommandeVente.idUser              = this.Vente.idUser;
      }else{
          this.tableCommandeVente.idUser            = this.AuthenticationService.currentUserValue.id;
      }
      this.tableCommandeVente.commandeDetail        = this.commandeDetail;
      this.tableCommandeVente.commandePaiement      = this.commandePaiement ;
      this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
      this.tableCommandeVente.prixArticle           = this.prixArticle;
      this.tableCommandeVente.nom                   = this.Vente.nom;
      this.tableCommandeVente.id                    = this.Vente.id;
      this.tableCommandeVente.num                   = this.Vente.num;
      this.tableCommandeVente.numm                  = this.Vente.numm;
      this.tableCommandeVente.remise                = this.Vente.remise;
      this.tableCommandeVente.idDepot               = this.Vente.idDepot;
      this.tableCommandeVente.idDepotVente          = this.idDepotVente;
      this.tableCommandeVente.idClient              = this.Vente.idClient;
      this.tableCommandeVente.idRevendeur           = this.Vente.idRevendeur;
      this.tableCommandeVente.nom                   = this.nomDepot;
      this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
      this.tableCommandeVente.nbon                  = this.Vente.nbon;
      this.tableCommandeVente.observation           = this.Vente.observation;
      // this.tableCommandeVente.nbreBon            = this.nbreBon;
      // console.log(this.tableCommandeVente);
      this.data
        .vente(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string,numm:number }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.numm);
            // this.Vente.numm = res.numm;
            // let el: HTMLElement = this.printBtn.nativeElement;
            // el.click();
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.action='';
            // this.vente();
            this.getArticles();
            this.venteDetail();
            this.paiementDetail();
            this.displaybarcode   = false;
            this.blockedDocument  = false;
            this.displayDialog    = false;
            this.desablePrint     = false;
          } else {
            alert(res.message);
          }
        });
    } else {
      // alert('add')
        this.tableCommandeVente.action                = "Insert";
        this.tableCommandeVente.DBase                 = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
        this.tableCommandeVente.remise                = this.Vente.remise;
        this.tableCommandeVente.commandeDetail        = this.commandeDetail;
        this.tableCommandeVente.commandePaiement      = this.commandePaiement ;
        this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
        this.tableCommandeVente.prixArticle           = this.prixArticle;
        this.tableCommandeVente.idDepot               = this.Vente.idDepot;
        this.tableCommandeVente.idDepotVente          = this.idDepotVente;
        this.tableCommandeVente.idClient              = this.Vente.idClient;
        this.tableCommandeVente.idRevendeur           = this.Vente.idRevendeur;
        this.tableCommandeVente.nom                   = this.nomDepot;
        this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
        this.tableCommandeVente.nbon                  = this.Vente.nbon;
        this.tableCommandeVente.nbreBon               = this.nbreBon;
        this.tableCommandeVente.observation           = this.Vente.observation;
        // console.log(this.tableCommandeVente);
        
        this.data
          .vente(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string,numm:number }) => {
            if (res.message == "Opération de modification réuissie") {
              this.Vente.numm = res.numm;

              // alert(this.Vente.numm);
              if(this.Vente.numm){
                // let el: HTMLElement = this.printBtn.nativeElement;
                // el.click();
              }
              // this.vente();
              this.getArticles();
              this.venteDetail();
              this.paiementDetail();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displaybarcode   = false;  
              this.displayDialog = false;
              this.blockedDocument=false;
              this.desablePrint = false;
              this.displaybarcode=false;
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    } else {
      this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Vous-avez oublier le clients !!!"});
    }
  }
  delete() {
    // alert();
    //   this.confirmationService.confirm({
    //       message: 'Do you want to delete this record?',
    //       header: 'Delete Confirmation',
    //       icon: 'pi pi-info-circle',
    //       accept: () => {
      if (confirm("êtes vous sure?")) {
        // console.log(this.Vente);
            this.Vente.action = "Delete";
            this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .vente(this.Vente)
              .toPromise()
              .then((res: { message: string }) => {
                if ((res.message = "Opération de supression réuissie")) {
                  this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                  // this.vente();
                  this.venteDetail();
                  this.displayDialog = false;
                } else {
                  alert("Nonnnn");
                }
              });
            // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
              
          // },
          // reject: () => {
          //     // this.messageService.add({severity:'info', summary:'Rejected', detail:'لقذ تم حذف'});
          //     this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          // }
      // });
    //
    // });
  }
  }
  
  exportexcel(): void {
  let fileName = "Situation des ventes du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
    /* table id is passed over here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    var title =
      "Situation du " +
      this.dateSituationDebut +
      " au " +
      this.dateSituationFin;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      "du " + this.dateSituationDebut + " au " + this.dateSituationFin
    );

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  printBL(idCommande) {
    this.displayDialog = false;
    // console.log(event);
    // this.Vente.id = event.data.idCommande;
    // this.Vente.dateCommandee = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    // this.Vente.remise = event.data.remise;

    let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
    this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
    this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
    // console.log(this.requetteCommandeVentes);
    this.totalQantite();
    this.prepareCommandeDetail();
  }
  newDonnee: boolean;
  showDialog() {
    this.newDonnee = true;
    this.Vente = {};
    this.displayDialog = true;
  }
  showDialogToAdd() {
    this.afficher= true;
    this.showButon = false;
    this.showButonSauvgarder = false;
    this.showButonAvance = false;
    this.newDonnee = true;
    this.tableAvances = [];
    this.totalAvance=0;
    this.Vente = {};
    this.Vente.remise = 0;
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.requetteCommandeVentes = [];
    this.tableAvance ={};
    this.tableAvances =[];
    this.tableClient ={};
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
    // this.Vente.idClient='1';
    if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
      this.switchCodeBarre();
    }
    this.tableCommandeVente.idDepot=1;
    // this.setFocusCodeBarre();
    if(this.Vente.cloture==false || this.Achat.cloture==undefined ){
      // this.setFocusCodeBarre();
      this.autoCompleteObject.focusInput();
    }
    let rechercheDB =this.Donnees.find((x) => x.actif == true);
    if(rechercheDB){
      this.Vente.DataBase= rechercheDB.data;
    }
    // let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
    // console.log(rechercheClient);
    
    // if(rechercheClient){
    //   this.ClientF = rechercheClient;
    //   this.Vente.idClient = rechercheClient.id;
    // }

    // this.tauxRemise=0; 
    this.displayDialog = true;
    this.exclut("1");
  }

  prixRemise(){
    this.tableCommandeVente.prixVente = this.prixVente1*(1-this.tableCommandeVente.remise/100);     
  }

  addArticle(data) {
    // console.log(data);
    var prixAchat =data.prixAchat*1;
    var prixVente =data.prixVente*1;
    if(prixVente <= prixAchat){
      this.colorInput='red';
      this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
    }else{
      this.colorInput='';
      if(data.idArticle && data.idDepot && data.quantite && data.prixVente){
          this.findDepot(data.idDepot);
          // alert(data.idDepot);
          // alert( data.prixAchat);
          // console.log(this.showButon);
          this.index = this.tableCommandeVentes.findIndex(
            (x) => x.idArticle == data.idArticle
          );
          
          if (this.index!=-1) {
            // alert('HI'+this.index);
          }
          if(this.showButon==false){
            if( data.hasOwnProperty('prixAchat')==false){
              data.prixAchat=0;
            }
            data.total=data.prixVente*data.quantite;
            data.marge=(data.prixVente - data.prixAchat)*data.quantite;
            if(this.tableCommandeVente.autocomplete){
              data.codeBarre=this.tableCommandeVente.autocomplete.codeBarre;
              data.reference=this.tableCommandeVente.autocomplete.reference;
            }
            // alert(prixVente);

            if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
              this.tableCommandeVentes.push(data);
              this.showButon = false;
              this.nbre=this.tableCommandeVentes.length-1;
              if(this.tableCommandeVentes.length==1){
                this.higthCol='500px';
              }else if(this.tableCommandeVentes.length>1) {
                let hight=500-(this.tableCommandeVentes.length-1)*20;
                this.higthCol=hight + 'px';
              }
              this.totalQantite();
              // this.prepareCommandeDetail();
              this.initialiser();
              this.tableCommandeVente = {};
              this.selectedArticle="";
              this.tableCommandeVente.idDepot=data.idDepot;
              if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
                this.switchCodeBarre();
              }
            } else {
              alert("Entrer un Article !");
              if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
                this.switchCodeBarre();
              }
            }
            this.desablePrint=true;
            this.setFocusArticle();
            // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
          }else{
            if(this.tableCommandeVente.prixVente<this.prixAchat){
              this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
              this.tableCommandeVente.prixVente=this.prixAchat;
            }else{
            // 
              if( data.hasOwnProperty('prixAchat')==false){
                data.prixAchat=0;
              }
              this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
              this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
              this.tableCommandeVentes[this.index].depot            = data.depot;
              this.tableCommandeVentes[this.index].designation      = data.designation;
              this.tableCommandeVentes[this.index].quantite         = data.quantite;
              this.tableCommandeVentes[this.index].remise           = data.remise;
              this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
              this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
              this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
              this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
              this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
              this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
              this.tableCommandeVentes[this.index].codeBarre        = this.tableCommandeVente.autocomplete.codeBarre;
              this.tableCommandeVentes[this.index].reference        = this.tableCommandeVente.autocomplete.reference;
              this.idClient                                         = this.tableCommandeVente.idClient;
              this.showButon = false;
              this.totalQantite();
              // this.prepareCommandeDetail();
              this.initialiser();
              if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
                this.switchCodeBarre();
              }
          }
        }
      }else{
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      }      
    }
  }

  updateArticle(data) {    
    if((data.prixVente*1) <= (this.prixAchat*1)){
      this.colorInput='red';
      this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
    }else{
      if( data.hasOwnProperty('prixAchat')==false){
        data.prixAchat=0;
      }
      if(data.idArticle && data.idDepot && data.quantite && data.prixVente){
        this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
        this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
        this.tableCommandeVentes[this.index].depot            = data.depot;
        this.tableCommandeVentes[this.index].designation      = data.designation;
        this.tableCommandeVentes[this.index].quantite         = data.quantite;
        this.tableCommandeVentes[this.index].remise           = data.remise;
        this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
        this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
        this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
        this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
        this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
        this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
        this.idClient                                         = this.tableCommandeVente.idClient;
        this.showButon = false;
        this.totalQantite();
        // this.prepareCommandeDetail();
        this.initialiser();
        this.findDepot(data.idDepot);
        this.tableCommandeVente.idDepot=data.idDepot;
        
      }else{
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      }
    }
  }
  addAvance(data) {
    //  console.log(data);
    if(data.idModePaiement && data.montant){
        this.findModePaiement(data.idModePaiement);
        if(this.showButonAvance==false){
            this.tableAvances.push(data);
            this.showButonAvance = false;
            this.calculTotalAvance();
            this.tableAvance = {};
            this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
            this.tableAvance.idModePaiement=1;
        }else{
          // alert(data.idModePaiement);
            this.tableAvances[this.indexAvance].idModePaiement    = data.idModePaiement;
            this.tableAvances[this.indexAvance].montant           = data.montant;
            this.tableAvances[this.indexAvance].modePaiement      = data.modePaiement;
            this.tableAvances[this.indexAvance].dateEcheance      = data.dateEcheance;
            this.tableAvances[this.indexAvance].reference         = data.reference;
            this.showButonAvance                                  = false;
            // this.totalQantite();
            this.calculTotalAvance();
            this.tableAvance = {};
            this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
            this.tableAvance.idModePaiement=1;
        }
    }else{
      this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
    }
  }
  
  addClient(data) {
    //  console.log(data);
    if(data.nom){
        if(this.showButonClient==false){
            this.tableClient.action = "InsertVente";
            this.tableClient.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .client(this.tableClient)
              .toPromise()
              .then((res: { message: string }) => {
                if (res.message == "Opération de modification réuissie") {
                  // alert(res.message);
                  this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
                  this.displayDialogClient  = false;  
                  this.blockedDocument      = false;
                  this.client();
                  this.nom="";
                } else {
                  this.messageService.add({severity:'warn', summary: 'Attention !!! ', detail: res.message});
                }
              });
          } else {
            this.tableClient.action = "Update";
            this.tableClient.DBase  = this.AuthenticationService.currentUserValue.DBase;
            this.data
              .client(this.tableClient)
              .toPromise()
              .then((res: { message: string }) => {
                if (res.message == "Opération de modification réuissie") {
                  // alert(res.message);
                  this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
                  this.displayDialogClient  = false;  
                  this.blockedDocument      = false;
                  this.client();
                  this.nom="";
                } else {
                  alert(res.message);
                }
              });
          }
    }else{
      this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
    }
  }

  addArticleBarCode(data,colonne){
    // console.log(data,colonne);
      let recherche:any;
      if (colonne=="codeBarre"){
        recherche = this.Articles.find((x) => x.codeBarre == data);
      }else if (colonne=="idArticle"){
        recherche = this.Articles.find((x) => x.idArticle == data.idArticle);
      }else{
        recherche = this.Articles.find((x) => x.designation == data.designation);
      }
      //  console.log(recherche);
      if(!recherche){
        this.playAudio(data);
      }else{
        data           = recherche;
        // console.log(data);
        data.total     = recherche.prixVente;
        data.marge     = (recherche.prixVente-recherche.prixAchat);
        data.idArticle = recherche.idArticle;
        data.nbon      = 0;
        let exist = this.requetteCommandeVentes.find((x) => x.idArticle == data.idArticle);
        if(!exist){
          // console.log(exist);
          data.quantite=1;
          data.idDepot=1;
          this.addArticle(data);
          this.setFocus();
          this.requetteCommandeVentes.reverse();
          this.designation=data.designation;
          this.quantite=1;
        }else{
          var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
          this.tableCommandeVentes[index].quantite=exist.quantite+1;
          this.tableCommandeVentes[index].total = recherche.prixVente*this.tableCommandeVentes[index].quantite;
          this.designation=this.tableCommandeVentes[index].designation;
          this.quantite=this.tableCommandeVentes[index].quantite;
          this.tableCommandeVentes.splice(0, 0, this.tableCommandeVentes.splice(index, 1)[0]);
          this.totalQantite();
          this.prepareCommandeDetail();
          this.tableCommandeVente = {};
          this.selectedArticle="";
        }
        this.selectedCommandeDetail = this.tableCommandeVentes[0];
      }
    this.showButon = false;
    this.BarCode="";
  }

  playAudio(data){
    let audio = new Audio();    
    audio.src = this.myUrl+'/API/API/uploads/alert.mp3';
    audio.load();
    audio.play();
    this.messageService.add({severity:'error', summary: 'Attention !!!', detail: 'Article non truové !!! '+data});
  }
  
  findArticle(idArticle) {
    let recherche = this.Articles.find((x) => x.id == idArticle);
    this.designation = recherche.designation;
    this.idArticle = idArticle;
    // alert(this.idArticle);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  showCLient(){
    this.displayDialogClient = true;
  }
  totalQantite() {
    let totalMontant = 0;
    let totalMarge = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.total;
      totalMarge += data.marge * 1;
      totalQte += data.quantite * 1;
    }
    this.totalQte = totalQte;
    this.totalMarge = totalMarge;
    this.totalMontant = totalMontant;
    if(this.totalMontant+this.reste>this.Vente.plafond){
      this.block=true;
      this.messageService.add({severity:'error', summary: 'Attention !!!', detail: 'Le Client à depassé le plafand !!! '});
    }else{
      this.block=false;
    }
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteAvance(idModePaiement) {
    var index = this.tableAvances.findIndex((x) => x.idModePaiement == idModePaiement);
    this.tableAvances.splice(index, 1);
    this.calculTotalAvance();
  }
  deleteCommande(idDetail,idArticle) {
    // console.log(this.tableCommandeVentes);
    // alert(idDetail+'||'+idArticle);
    this.desablePrint=true;
    if (idDetail !=undefined){
      var index = this.tableCommandeVentes.findIndex(
        (x) => x.idDetail == idDetail 
      );
    }else{
     var index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == idArticle
      );      
    }
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    this.prepareCommandeDetail();
    this.setFocus();
  }

  prepareCommandeDetail() {
    // console.log(this.tableCommandeVentes);
    
    if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
      // this.switchCodeBarre();
    }
    //--------Détail Commande---------

    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idCommande','" +
        data.idDepot      + "','" +
        data.idArticle    + "','" +
        data.remise       + "','" + 
        data.quantite     + "','" +
        data.prixAchat    + "','" + 
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    // console.log(this.commandeDetail);

    //--------Détail Paiement---------
    this.commandePaiement = "";
    // idCommade	idClient	dateReglement	montant	modePaiement	reference	dateEcheance	regle	annule	
    var idClient =0;
    if(this.Clients){
      let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase()  == "COMPTOIR");
      if(rechercheClient){
        idClient = rechercheClient.id;
      }
    }
    // console.log(this.Vente.idClient);
    // console.log(idClient);
    
    if(this.Vente.idClient==idClient){
      this.commandePaiement           =
            "('idCommande','"         +
            this.Vente.idClient       + "','" +
            this.Vente.dateCommandee  + "','" +
            (this.totalMontant - this.Vente.remise) + "','1','',NULL,'o'),";
    }else{
      for (let data of this.tableAvances) {
        this.commandePaiement         +=
          "('idCommande','"         +
          this.Vente.idClient       + "','" +
          this.Vente.dateCommandee  + "','" +
          data.montant              + "','" +
          data.idModePaiement         + "','" +
          data.reference            + "','" +
          data.dateEcheance         +
          "','o'),";
        };
    }
    this.commandePaiement += "$";
    this.commandePaiement = this.commandePaiement.replace(",$", ";");
    this.commandePaiement = this.commandePaiement.replace("undefined", "");
    // console.log(this.commandePaiement);

//-----------------vers Variation du Stock ----------------//

this.commandeDetailDepot = "";
for (let data of this.tableCommandeVentes) {
  this.commandeDetailDepot +=
    "('idCommande','"         +
    this.Vente.dateCommandee  +  "','" +
    data.idDepot              +  "','" +
    data.idArticle            +  "','" +
    data.quantite             +  
    "','Vente'),";
}
this.commandeDetailDepot += "$";
this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");
// console.log(this.commandeDetailDepot);
    //Achat Camion //prixAchat,prixVenteGros, prixVenteDetail,prixVente
    // let prixArticleAchat= "";
    // let prixArticleVenteGros= "";
    // let prixArticleVenteDetail= "";
    // let prixArticleVente= "";
    // let prixArticle= "";
    // this.prixArticle= "UPDATE article ";

    //--------vers Article ---------
    // var str =  " WHERE id in (" + prixArticle + ") ";
    // str = str.replace(", )", ")");
    // this.prixArticle += 
    // " SET prixAchat     = (case " + prixArticleAchat + " end)," + 
    // " prixVenteGros     = (case " + prixArticleVenteGros + " end)," +  
    // " prixVenteDetail   = (case " + prixArticleVenteDetail + " end)," +  
    // " prixVente         = (case " + prixArticleVente + " end)" +  
    // str;
    // this.prixArticle=this.prixArticle.replace("undefined", "0"); 
    // console.log(this.prixArticle );*
    if(this.action!='save'){
      this.initialiser();
    }    
    // alert(this.tableCommandeVente.idFournisseur);
  }

  onRowSelect(event) {
    this.afficher= false;
    // if (!event.data.cloture){
      // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
      // if (this.able) {
        this.displayDialog = true;
        this.artilcesClient=[];
        this.artilceClient={}; 
        this.tableAvances=[];
        this.tableAvance={}; 
        this.Vente.id = event.data.idCommande;
        this.Vente.num = event.data.num;
        this.Vente.numm = event.data.numm;
        this.Vente.nbreBon = event.data.nbreBon;
        // alert(this.Vente.nbreBon);
        this.Vente.dateCommandee = event.data.dateCommande;
        this.Vente.dateCommandeTicket = event.data.dateCommandeTicket;

        let rechercheRevendeur = this.Revendeurs.find((x) => x.nom == event.data.revendeur);
        if(rechercheRevendeur==undefined){
          this.Vente.idRevendeur=0;
        }else{
          this.Vente.idRevendeur = rechercheRevendeur.id;
        }
        let rechercheDB =this.Donnees.find((x) => x.actif == true);
        if(rechercheDB){
          this.Vente.DataBase= rechercheDB.data;
        }
        // let vvv=JSON.stringify(event.data.idClient);
        
        this.Vente.idClient     = JSON.stringify(event.data.idClient);
        this.Vente.idDepot      = event.data.idDepot;
        this.Vente.remise       = event.data.remise;
        this.Vente.nom          = event.data.nom;
        this.Vente.nbon         = event.data.nbon;
        this.Vente.idUser       = event.data.idUser;
        this.Vente.nomComplet   = event.data.nomComplet;
        this.Vente.adresse      = event.data.adresse;
        this.Vente.ville        = event.data.ville;
        this.Vente.abreviation  = event.data.abreviation;
        this.Vente.codeClient   = event.data.codeClient;
        this.Vente.codeClient   = event.data.codeClient;
        this.Vente.observation  = event.data.observation;
        // console.log(this.Vente.nomComplet.substring(0, 1));
        this.Vente.cloture      = event.data.cloture;
        this.Exclut             = event.data.exclut;
        this.tableCommandeVente.idDepot=1;
        this.showButonAvance    = false;
        // alert (this.Vente.idClient);

        let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
        // console.log(recherche);
        if(recherche){
          this.tableCommandeVentes      = JSON.parse(JSON.stringify(recherche));
          this.requetteCommandeVentes   = JSON.parse(JSON.stringify(recherche));
          this.nbre=this.tableCommandeVentes.length-1;
          if(this.tableCommandeVentes.length==1){
            this.higthCol='500px';
          }else if(this.tableCommandeVentes.length>1) {
            let hight=500-(this.tableCommandeVentes.length-1)*20;
            this.higthCol=hight + 'px';
          }
          this.requettesCommandeVentes = JSON.parse(JSON.stringify(this.requetteCommandeVentes));

          //SUPP FAMILLE DE L'ARTICLE
          // this.requettesCommandeVentes.forEach((row,index)=>{
          //   this.Familles.forEach((rowFamilles)=>{
          //     var regEx = new RegExp(rowFamilles.type, "ig");
          //     this.requettesCommandeVentes[index].designation=row.designation.replace(regEx,' ');
          //   });
            // this.requettesCommandeVentes[index].designation=row.designation.replace('Entretien',' ');
            // this.requettesCommandeVentes[index].designation=row.designation.replace('Recharge',' ');
            // this.requettesCommandeVentes[index].designation=row.designation.replace('Fourniture',' ');
            // this.requettesCommandeVentes[index].designation=row.designation.replace('Vérification',' ');
          // })
          this.paiementDetail();
          this.tableAvances = [];
          this.totalAvance=0;
          if(this.tableDetailPaiements){
            let rechercher = this.tableDetailPaiements.filter((x) => x.idCommande == event.data.idCommande);        
            if(rechercher){
              this.tableAvances = rechercher;
            }
          }
          this.calculTotalAvance();     
          this.totalQantite();
          this.prepareCommandeDetail();
          this.exclut(event.data.idClient);

          // console.log(this.Vente);          
        }

      // }
    // }
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }
  onRowSelectAvance(event) {
    
    this.tableAvance.idModePaiement   = event.data.idModePaiement;
    this.tableAvance.modePaiement     = event.data.modePaiement;
    this.tableAvance.montant          = event.data.montant;
    this.tableAvance.dateEcheance     = event.data.dateEcheance;
    this.tableAvance.reference        = event.data.reference;
    this.showButonAvance              = true;
    this.showButonSauvgarder          = true;
    this.indexAvance = this.tableAvances.findIndex((x) => x.idModePaiement == event.data.idModePaiement && x.montant ==event.data.montant);
    this.setFocusAvance();
  }
  onRowSelectCommandeDetail(event) {
    // console.log(event.data);
    this.setFocusArticle();
    this.rechercheArticle = this.Articles.find((x) => x.id == event.data.idArticle);
    this.quantiteVenteGros                    = this.rechercheArticle.quantiteVenteGros;
    this.quantiteVenteDetail                  = this.rechercheArticle.quantiteVenteDetail;
    this.prixAchat                            = this.rechercheArticle.prixAchat;
    this.prixVenteGros                        = this.rechercheArticle.prixVenteGros;
    this.prixVenteDetail                      = this.rechercheArticle.prixVenteDetail;
    this.prixVente1                           = this.rechercheArticle.prixVente;
    this.designation                          = this.rechercheArticle.designation;
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.dropdownArticle=false;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idArticle == event.data.idArticle
    );
    // // alert(this.index);
    this.newDonnee = false;
    this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
    // if(typeof event.data.autocompleteCodeBarre =="object"){
    //   this.tableCommandeVente.autocompleteCodeBarre=event.data.autocompleteCodeBarre;
    // }else{
      this.tableCommandeVente.autocompleteCodeBarre=event.data;
    // }
    // if(typeof event.data.autocomplete =="object"){
    //   this.tableCommandeVente.autocomplete=event.data.autocomplete;
    // }else{
      this.tableCommandeVente.autocomplete=event.data;
    // }
    this.displayDialog = true;
    // console.log( this.tableCommandeVente);
  }
  cloneDonneeCommandeDetail(c: Vente): Vente {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  onRowSelectBarreCode(event){
    // console.log(event);
    // alert(event.data.idArticle);
    this.idArticle=event.data.idArticle;
    this.currentNumber='0';
    this.blockedcalculator=true;
  }
  close() {
    this.setFocusArticle();
    this.displayDialog = false;
    this.displayCharge = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.Charge={};
    this.Charges=[];
    this.requettesCommandeVentes = [];
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.Vente.id=null;
    this.Vente.idClient="";
    this.Vente.dateCommandee = new Date()
    .toISOString()
    .split("T")[0];
    this.tableCommandeVente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.prepareCommandeDetail();
    this.Vente.nbon='0';
    this.Vente.remise=0;
    // setTimeout(() => this.inputEl.nativeElement.focus());
  }
  initialiserAvance() {
    this.tableAvance={};
    this.showButonAvance=false;
    this.tableAvance.idModePaiement=1;
    this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
  }
  initialiser() {
    // this.desablePrint=false;
    this.colorInput='';
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = this.tableCommandeVentes;
    this.showButon=false;
    this.prixVenteGros=undefined;
    this.prixVenteDetail=undefined;
    this.prixVente1=undefined;
    this.tableCommandeVente.idDepot=1;
    // this.setFocusCodeBarre();
    if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
      // this.setFocusCodeBarre();
      this.autoCompleteObject.focusInput();
    }
    // this.tableCommandeAchat.idArticle = null;
    // this.tableCommandeAchat.quantite = "";
    // this.tableCommandeAchat.prixAchat = "";
    // this.tableCommandeAchat.prixVente = "";
    // this.tableCommandeAchat.prixVenteDetail = "";
    // this.tableCommandeAchat.prixVenteGros = "";
    // this.showButon = false;
  }
}
