import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AuthenticationService } from "./authentication.service";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import {Message,MessageService} from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Parametre } from './model/parametre';
import { DataService } from './data.service';
import { DataBase } from "./model/dataBase";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class AppComponent implements OnInit {
  isAdmin: boolean = false;
  title = "";
  myUrl: string = `${environment.urlApi}`;
  myUrlBackUp: string;
  items: MenuItem[];
  itemsMenu: any[];
  itemsSubMenu: any[];
  itemss: MenuItem[];
  menuAdmi: MenuItem[];
  menuUser: MenuItem[];
  Parametre: Parametre = {};
  Parametres: Parametre[];
  dataBase: DataBase = {};
  dataBases: DataBase[];
  nbreRowTable:number;
  nbreRowDialoge:number;
  raisonSociale:string;
  annee:string;
  titre:string="Bienvenu";
  
  francais: boolean =false;
  langueDirection: string ="ltr";
  langueLbl: string ="العربية";

  intervalId      : any;
  constructor(
    public AuthenticationService: AuthenticationService,
    private router: Router,
    private data: DataService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {
    this.itemsMenu = [
      {
        id: "1",
        label: "Articles",
        labelAr: "السلعـــة",
        icon: "pi pi-fw pi-microsoft",
        image: "produit.png",
        Link: "articles",
        items: [
          {
              label: "Articles",
              labelAr: "السلعـــة",
              icon: "pi pi-fw pi-microsoft",
              image: "produit.png",
              routerLink: "articles"
          },
          {
              label: "Articles restaures",
              labelAr: "السلعـــة",
              icon: "pi pi-fw pi-microsoft",
              image: "produit.png",
              routerLink: "articlesRestore"
          },
          {
            label: "Stock",
            labelAr: "المخزون",
            icon: "pi pi-fw pi-filter",
            image: "Stock.png",
            routerLink: "VariationStockDepot",
          },
          {            
            label: "Mouvement",
            labelAr: "تعديل المخزون",
            icon: "pi pi-fw pi-spinner",
            image: "variationStock.png",
            routerLink: "MouvementStockDepot",
          },
          {
            label: "Stock Alert",
            labelAr: "تنبيه",
            icon: "pi pi-info-circle",
            image: "Stock.png",
            routerLink: "alertStock",
          },
          {
            label: "Dépots",
            labelAr: "المخزن",
            icon: "pi pi-directions",
            image: "Stock.png",
            routerLink: "depots",
          },
        ]
      },
      {
        id: "2",
        label: "Achats",
        labelAr: "الشراء",
        icon: "pi pi-fw pi-shopping-cart",
        image: "achat.png",
        Link: "Accueil",
        userControl:"able",
        items: [
          {
            label: "Achat",
            labelAr: "الشراء",
            icon: "pi pi-fw pi-plus",
            image: "achat.png",
            routerLink: "achats",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Achat",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationAchat",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementFournisseur",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirsAchat",
          },
        ],
      },
      {
        id: "3",
        label: "Ventes",
        labelAr: "البيـــع",
        icon: "pi pi-fw pi-shopping-cart",
        image: "vente.png",
        Link: "Accueil",
        
        items: [
          {
            label: "Commande",
            labelAr: "البيع",
            icon: "pi pi-fw pi-plus",
            image: "vente.png",
            routerLink: "ventesPro",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Vente",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationsVentes",
          },
          {
            label: "Paiement par BON",
            labelAr: "حســب البون",
            icon: "pi pi-fw pi-sliders-v",
            routerLink: "situationPaiementVente",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementClient",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirs",
          },
        ],
      },
      {
        id: "4",
        label: "Factures",
        labelAr: "الفـــاتورات",
        icon: "pi pi-fw pi-calendar",
        image: "facturation.png",
        Link: "Accueil",
        
        items: [
          {
            label: "Factures",
            labelAr: "الفـــاتورات",
            icon: "pi pi-fw pi-plus",
            image: "facturation.png",
            routerLink: "facture",
          },
          {
            label: "Fournisseurs",
            labelAr: "الممول",
            icon: "pi pi-fw pi-pencil",
            image: "vendeur.png",
            routerLink: "fournisseurs",
          },
          {
            label: "situation Vente",
            labelAr: "حســب الــسلعة",
            image: "factures.png",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationFacture",
          },
          {
            label: "Evolution Stock",
            labelAr: "مسار المخزون",
            icon: "pi pi-fw pi-sliders-v",
            image: "Stock.png",
            routerLink: "situationStockFacture",
          },
          {
            label: "Paiement",
            labelAr: "الحـالة المـالية",
            icon: "pi pi-fw pi-dollar",
            image: "paiement.png",
            routerLink: "situationPaiementClientFacture",
          },
          {
            label: "Avoir",
            labelAr: "إرجاع الــسلعة",
            icon: "pi pi-fw pi-replay",
            image: "retour.png",
            routerLink: "avoirFacture",
          },
        ],
      },
      { 
        id: "5",
        label: "Parametres",
        labelAr: "ظبــط",
        icon: "pi pi-fw pi-cog",
        image: "Parametre.png",
        Link: "Accueil",
        items: [
          {
            label: "BACKUP",
            labelAr: "حفـظ البيانات",
            icon: "pi pi-fw pi-save",
            image: "backup.png",
            routerLink: '<a href="{{myUrl}}/API/API/backup.php?DBase={{AuthenticationService.currentUserValue.DBase}}" target="_blank">',
          },
          {
            label: "Utilisateurs",
            labelAr: "المستعمــل",
            icon: "pi pi-fw pi-user-edit",
            image: "vente.png",
            routerLink: "GestionUser",
          },
          {
            label: "Commercials",
            labelAr: "التجاري",
            icon: "pi pi-fw pi-user-edit",
            image: "vente.png",
            routerLink: "commercial",
          },
          {
            label: "Familles",
            labelAr: "النــوع",
            icon: "pi pi-fw pi-tag",
            image: "type.png",
            routerLink: "types",
          },
          {
            label: "Modes paiement",
            labelAr: "الاستخلاص",
            image: "type.png",
            icon: "pi pi-fw pi-tag",
            routerLink: "modePaiement",
          },
          {
            label: "Charges",
            labelAr: "الصـــوائر",
            icon: "pi pi-fw pi-tag",
            image: "charges.png",
            routerLink: "charges",
          },
          // {
          //   label: "Dépôts",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "Depots",
          // },
          // {
          //   label: "Paramètre",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "parametre",
          // },
        ]
      }, 
    ];
    this.items = [
      {
        label: "Acceuil  |  الاسـتقبال",
        icon: "pi pi-fw pi-home",
        routerLink: "Accueil",
      },
      {
        label: "Achat |  الشراء",
        icon: "pi pi-fw pi-shopping-cart",
        items: [
          {
            label: "Commande | الفاتورة",
            icon: "pi pi-fw pi-plus",
            routerLink: "achats",
          },
          {
            label: "Fournisseurs | الممول",
            icon: "pi pi-fw pi-pencil",
            routerLink: "fournisseurs",
          },
          { separator: true },
          {
            label: "Paiement Achat",
            icon: "pi pi-fw pi-dollar",
            routerLink: "situationPaiementAchat",
          },          
          {
            label: "situation Achat",
            icon: "pi pi-fw pi-chart-line",
            routerLink: "situationAchat",
          },
          {
            label: "Avoir",
            icon: "pi pi-fw pi-replay",
            routerLink: "avoirsAchat",
          },
        ],
      },
      {
        label: "Vente  |  البيـــع",
        icon: "pi pi-fw pi-calendar",
        items: [
          {
            label: "Comptoire",
            icon: "pi pi-angle-double-right",
            items: [
              {
                label: "Commande | الفاتورة",
                icon: "pi pi-fw pi-pencil",
                routerLink: "ventesPro",
              },
              {
                label: "Situation Vente",
                icon: "pi pi-fw pi-chart-line",
                routerLink: "situationsVentes",
              },{ separator: true },
              {
                label: "Paiement par BON",
                icon: "pi pi-fw pi-sliders-v",
                routerLink: "situationPaiementVente",
              },
              { separator: true },
              {
                label: "Paiement Client",
                icon: "pi pi-fw pi-sliders-v",
                routerLink: "situationPaiementClient",
              },
              { separator: true },
              {
                label: "Avoir",
                icon: "pi pi-fw pi-sliders-v",
                routerLink: "avoir",
              },
            ],
          },
      {
            label: "Facture  |  الفاتورة",
            icon: "pi pi-angle-double-right",
            items: [
              {
                label: "Commande | الفاتورة",
                icon: "pi pi-fw pi-pencil",
                routerLink: "facture",
              },
              {
                label: "Situation Vente",
                icon: "pi pi-fw pi-chart-line",
                routerLink: "situationsFactures",
              },{ separator: true },
              {
                label: "Paiement Facture",
                icon: "pi pi-fw pi-sliders-v",
                routerLink: "situationPaiementFacture",
              },              {
                label: "Avoir Facture",
                icon: "pi pi-fw pi-sliders-v",
                routerLink: "avoirFacture",
              },
            ],
          },
      {
        label: "Devis | الــتسعيرة",
        icon: "pi pi-fw pi-calendar",
        routerLink: "devis",
      },
          {
            label: "Clients  |  الزبــون",
            icon: "pi pi-fw pi-pencil",
            routerLink: "clients",
          },
          {
            label: "Commande Simplifier",
            icon: "pi pi-fw pi-plus",
            routerLink: "ventes",
          },
        ],
      },
      {
        label: "Articles | السلعـــة",
        icon: "pi pi-fw pi-microsoft",
        items: [
          {
              label: "Articles | السلعـــة",
              icon: "pi pi-fw pi-microsoft",
              routerLink: "articles"
          },
          {
              label: "Articles restaures",
              icon: "pi pi-fw pi-microsoft",
              routerLink: "articlesRestore"
          },
          {
            label: "Stock | المخزون",
            icon: "pi pi-fw pi-filter",
            routerLink: "VariationStockDepot",
          },
          {            
            label: "Mouvement de Stock | تعديل المخزون",
            icon: "pi pi-fw pi-spinner",
            routerLink: "MouvementStockDepot",
          },
          {
            label: "Stock Alert | تنبيه",
            icon: "pi pi-info-circle",
            routerLink: "alertStock",
          },
          {
            label: "Dépots | المخزن",
            icon: "pi pi-directions",
            routerLink: "depots",
          },

        ]
      },
      // {
      //   label: "Stock | المخزون",
      //   icon: "pi pi-fw pi-filter",
      //   items: [
      //     {
      //       label: "Stock | المخزون",
      //       icon: "pi pi-fw pi-filter",
      //       routerLink: "VariationStockDepot",
      //     },
      //     // {
      //     //   label: "stock Initial | أول مخزون",
      //     //   icon: "pi pi-fw pi-filter",
      //     //   routerLink: "stockInitial",
      //     // },
      //     // {
      //     //   label: "Stock Depot",
      //     //   icon: "pi pi-angle-double-right",
      //     //   items: [
      //     //     {
      //     //       label: "Ajouter Stock Depot",
      //     //       icon: "pi pi-plus",
      //     //       routerLink: "StockDepot",
      //     //     },
      //     //     {
      //     //       label: "Stock Depot",
      //     //       icon: "pi pi-calendar",
      //     //       routerLink: "StockDepots",
      //     //     },
      //     //     {
      //     //       label: "Variation Stock Depot",
      //     //       icon: "pi pi-calendar",
      //     //       routerLink: "VariationStockDepot",
      //     //     },
      //     //     {
      //     //       label: "MOUvement Stock Depot",
      //     //       icon: "pi pi-calendar",
      //     //       routerLink: "MouvementStockDepot",
      //     //     },
      //     //   ]
      //     // },
      //     {
      //       label: "Stock Alert | تنبيه",
      //       icon: "pi pi-info-circle",
      //       routerLink: "alertStock",
      //     },
      //     // {
      //     //   label: "Mouvement de Stock  | اضافة أو حذف",
      //     //   icon: "pi pi-refresh",
      //     //   routerLink: "variationStock",
      //     // },
      //   ],
      // },
      // {
      //   label: "Rendez-vous",
      //   icon: "pi pi-fw pi-calendar",
      //   routerLink: "rendezVous",
      // },
      {
        label: "Parametres | ظبــط",
        icon: "pi pi-fw pi-cog",
        items: [
          {
            label: "Utilisateurs | المستعمــل",
            icon: "pi pi-fw pi-user-edit",
            routerLink: "GestionUser",
          },
          {
            label: "Commercials | التجاري",
            icon: "pi pi-fw pi-user-edit",
            routerLink: "commercial",
          },      {
            label: "Chargee | الصوائر",
            icon: "pi pi-fw pi-bars",
            routerLink: "charges",
          },
          {
            label: "حفـظ البيانات",
            icon: "pi pi-fw pi-save",
            url: this.myUrlBackUp,
          },
          {
            label: "Familles | النــوع",
            icon: "pi pi-fw pi-tag",
            routerLink: "types",
          },
          {
            label: "Modes de paiement | الاستخلاص",
            icon: "pi pi-fw pi-tag",
            routerLink: "modePaiement",
          },
          // {
          //   label: "Dépôts",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "Depots",
          // },
          // {
          //   label: "Paramètre",
          //   icon: "pi pi-fw pi-tag",
          //   routerLink: "parametre",
          // },
        ]
      }, 
    ];
    this.menuAdmi = [
      {
        label: "Acceuil  |  الاسـتقبال",
        icon: "pi pi-fw pi-home",
        routerLink: "Accueil",
      },

      { separator: true },
      {
      label: "Vente  |  البيـــع",
      icon: "pi pi-fw pi-calendar",
      items: [
          {
            label: "Comptoire",
            icon: "pi pi-fw pi-shopping-cart",
            routerLink: "ventesPro",
            styleClass: 'menucus'
          },
          // {
          //   label: "Paiement Vente",
          //   icon: "pi pi-fw pi-sliders-v",
          //   routerLink: "situationPaiementVente",
          // }, 
          { separator: true },
          // {
          //   label: "Facture",
          //   icon: "pi pi-fw pi-file",
          //   routerLink: "facture",
          //   styleClass: 'menucus'
          // },
          // { separator: true },
          {
            label: "Devis",
            icon: "pi pi-fw pi-calendar",
            routerLink: "devis",
            styleClass: 'menucus'
          },          
        ],
      },
     

      { separator: true },
      // {
      //   label: "Achats",
      //   icon: "pi pi-fw pi-pencil",
      //   routerLink: "achats",
      //   styleClass: 'menucus'
      // },
      // { separator: true },
      {
        label: "Clients",
        icon: "pi pi-fw pi-users",
        routerLink: "clients",
        styleClass: 'menucus'
      },
      // {
      //   label: "Chargee",
      //   icon: "pi pi-fw pi-bars",
      //   routerLink: "charges",
      //   styleClass: 'menucus'
      // },
      // { separator: true },
      {
        label: "Stock",
        icon: "pi pi-fw pi-filter",
        routerLink: "VariationStockDepot",
        styleClass: 'menucus'
      },
      // { separator: true },
      {
        label: "Articles",
        icon: "pi pi-fw pi-microsoft",
        routerLink: "articles",
        styleClass: 'menucus'
      },
      // { separator: true },
      // {
      //   label: "Familles",
      //   icon: "pi pi-fw pi-tag",
      //   routerLink: "types",
      //   styleClass: 'menucus'
      // },
     ];
    this.menuUser = [
      // {
      //   label: "Facture Mode Simplifier",
      //   icon: "pi pi-fw pi-plus",
      //   routerLink: "ventes",
      // },
      {
        label: "Vente  |  البيـــع",
        icon: "pi pi-fw pi-plus",
        routerLink: "ventesPro",
      },
      { separator: true },
      {
        label: "Stock",
        icon: "pi pi-fw pi-filter",
        routerLink: "VariationStockDepot",
      },
    ];
    if (this.AuthenticationService.getLocalStorage() != '') {
      // console.log(this.AuthenticationService.currentUserValue);   
      this.AuthenticationService.GetToken();
      // console.log(this.AuthenticationService.tokenresp.data);       
    }else{
      // console.log("dddddddd");
    }
    if(this.AuthenticationService.currentUserValue.francais==false){
      this.francais=false;
      this.langueDirection="ltr";
      this.langueLbl="العربية";
      // alert(this.langueLbl);
    }else{
      this.francais=true;
      this.langueDirection="rtl";
      this.langueLbl="الفرنسية";
      // alert(this.langueLbl);
    }
    // this.myUrlBackUp = this.myUrl+"/API/API/backup.php?DBase="+this.AuthenticationService.currentUserValue.DBase;
    // alert(this.route.snapshot.component);

    this.isAdmin;
    this.database();
  }
  database() {
    this.dataBase.action = "SelectAnnee";
    this.dataBase.dataBase = this.AuthenticationService.currentUserValue.DBase;
    this.AuthenticationService
      .dataBase(this.dataBase)
      .toPromise()
      .then((x: DataBase[]) => (this.dataBases = x,this.finddataBase()));
  }
  finddataBase(){
    let dataBase = this.dataBases.find((x) => x.data == this.dataBase.dataBase);
    this.annee=dataBase.designation;

    let i=1;
    let j=1000;
    this.intervalId = setInterval(() => {
      this.AuthenticationService.getLocalStorage();
      i++;
      // console.log(i+' | ' +this.AuthenticationService.currentUserValue.groupe);
      if(i>3){
        clearInterval(this.intervalId);
        this.stopRefresh();
      }
      // if(this.AuthenticationService.currentUserValue){
      //     this.stopRefresh()
      // }
    }, j)
  }
  stopRefresh(){
    clearInterval(this.intervalId);
  //  console.log("vvvvvvvvv");      
  }
  // ste() {
  //   this.Parametre.action = 'SelectSte';
  //   this.data.parametre(this.Parametre).toPromise().then((x: Parametre[]) => ((this.Parametres = x, console.log(x))));
  //   this.affect();
  // }
    //       this.tableArticles.forEach((obj) => {
  //         if (obj.prixService < 1) {
  //           this.totalServie +=
  //             obj.prixService * obj.quantite * obj.prixVente -
  //             obj.prixService * obj.quantite;
  //         }
  //       });
  // affect(){
  //   this.Parametres.forEach(element => {
  //     this.raisonSociale=element.raisonSociale;
  //     alert (this.raisonSociale);
  //   });
  // }
  // parametre() {
	// 	this.Parametre.action = 'Select';
  //   this.data.parametre(this.Parametre).toPromise().then((x: Parametre[]) => ((this.Parametres = x,this.listeParam())));
  // }
  // listeParam(){
  //   this.Parametres.forEach((obj) => {
  //     console.log(obj.propriete);
  //         if (obj.propriete =='nbreRowTable') {
  //           this.nbreRowTable = obj.taille;
  //         }else{
  //           this.nbreRowDialoge = obj.taille;
  //         }
  //       });
  // }
  ngOnDestroy() {
    localStorage.removeItem("currentUser");
  }
  logout() {
    this.AuthenticationService.logout();
    this.router.navigate(["/"]);
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  action(id,titre){
    this.titre=titre;
    let recherche = this.itemsMenu.find((x) => x.id == id);
    this.itemsSubMenu=recherche.items;
  }
}
