import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr);

import { DataTablesModule } from 'angular-datatables';

import { FullCalendarModule, Calendar } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);
import { NgSelectModule } from '@ng-select/ng-select';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerModule } from "primeng/spinner";
import { MenubarModule } from "primeng/menubar";
import { ButtonModule } from "primeng/button";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputTextModule } from "primeng/inputtext";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TableModule } from "primeng/table";
import { DropdownModule } from "primeng/dropdown";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from "primeng/dialog";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { MultiSelectModule } from "primeng/multiselect";
import { CheckboxModule } from "primeng/checkbox";
import { KeyFilterModule } from "primeng/keyfilter";
import { TabViewModule } from "primeng/tabview";
import { CardModule } from "primeng/card";
import { FileUploadModule } from "primeng/fileupload";
import { InputSwitchModule } from "primeng/inputswitch";
import { FieldsetModule } from "primeng/fieldset";
import { PaginatorModule } from "primeng/paginator";
import { ListboxModule } from "primeng/listbox";
import { JwPaginationModule } from "jw-angular-pagination";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxPrintModule } from "ngx-print";
import { ChartModule } from "primeng/chart";
import { SidebarModule } from "primeng/sidebar";
import { ScrollPanelModule } from "primeng/scrollpanel";
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {BlockUIModule} from 'primeng/blockui';
import {TooltipModule} from 'primeng/tooltip';
import { NgxBarcodeModule } from 'ngx-barcode';
// import { RippleModule } from 'primeng/ripple';
// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";

import {} from "@ng-bootstrap/ng-bootstrap";
// import { ColorPickerModule } from "ngx-color-picker";
import { ColorPickerModule } from 'primeng/colorpicker';
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { GestionUserComponent } from "./gestion-user/gestion-user.component";
import { TypesComponent } from "./article/types/types.component";
import { ModePaiementComponent } from "./article/mode-paiement/mode-paiement.component";
import { ArticlesComponent } from "./article/articles/articles.component";
import { SousTypesComponent } from "./article/sous-types/sous-types.component";
import { ClientComponent } from "./Ventes/client/client.component";
import { AchatComponent } from "./achats/achat/achat.component";
import { VenteComponent } from "./Ventes/vente/vente.component";
import { PersonnelComponent } from "./Autres/personnel/personnel.component";
import { FournisseursComponent } from "./achats/fournisseurs/fournisseurs.component";
import { StockComponent } from "./stocks/stock/stock.component";
import { MyFilterPipe } from "./my-filter-pipe/my-filter-pipe";
import { SituationVenteComponent } from "./Ventes/situation-vente/situation-vente.component";
import { AccueilComponent } from "./accueil/accueil.component";
import { ModifierFactureComponent } from "./Factures/modifier-facture/modifier-facture.component";
import { RendezVousComponent } from "./Autres/rendez-vous/rendez-vous.component";
import { ChargesComponent } from "./Autres/charges/charges.component";
import { SituationPaiementComponent } from "./situation-paiement/situation-paiement.component";
import { SituationAchatComponent } from "./achats/situation-achat/situation-achat.component";
import { SalaireComponent } from "./salaire/salaire.component";
import { EcheancierComponent } from "./echeancier/echeancier.component";
import { AlertStockComponent } from "./stocks/alert-stock/alert-stock.component";
import { CalendarComponent } from "./Autres/calendar/calendar.component";
import { VariationStockComponent } from "./stocks/variation-stock/variation-stock.component";
import { AddStockComponent } from "./stocks/add-stock/add-stock.component";
import { VenteProComponent } from "./Ventes/vente-pro/vente-pro.component";
import { FactureComponent } from "./Factures/facture/facture.component";
import { DevisComponent } from "./Ventes/devis/devis.component";
import { SituationPaiementVenteComponent } from './Ventes/situation-paiement-vente/situation-paiement-vente.component';
import { SituationPaiementAchatComponent } from './achats/situation-paiement-achat/situation-paiement-achat.component';
import { SituationPaiementFactureComponent } from './Factures/situation-paiement-facture/situation-paiement-facture.component';
import { CryptComponent } from './crypt/crypt.component';
import { ParametreComponent } from './parametre/parametre.component';
import { StockDepotComponent } from './stocks/stockDepot/stockDepot.component';
import { StockDepotsComponent } from './stocks/stockDepots/stockDepots.component';
import { DepotsComponent } from './stocks/depots/depots.component';
import { AvoirComponent } from './Ventes/avoir/avoir.component';
import { AvoirsComponent } from './Ventes/avoirs/avoirs.component';
import { AvoirsAchatComponent } from './achats/avoirs-achat/avoirs-achat.component';
import { SituationPaiementClientComponent } from "./Ventes/situation-paiement-client/situation-paiement-client.component";
import { InitialStockComponent } from "./stocks/initial-stock/initial-stock.component";
import { SituationPaiementFournisseurComponent } from "./achats/situation-paiement-fournisseur/situation-paiement-fournisseur.component";
import { VariationStockDepotComponent } from "./stocks/variation-stock-depot/variation-stock-depot.component";
import { MouvementStockDepotComponent } from "./stocks/mouvement-stock-depot/mouvement-stock-depot.component";
import { ListesComponent } from "./article/listes/listes.component";
import { ListeDetailComponent } from "./article/listeDetail/listeDetail.component";
import { SituationFactureComponent } from "./Factures/situation-facture/situation-facture.component";
import { SituationStockComponent } from "./Factures/situation-stock/situation-stock.component";
import { BarreCodeComponent } from "./article/barreCode/barreCode.component";
import { TactileComponent } from './Ventes/tactile/tactile.component';
import { SituationPaiementClientFactureComponent } from "./Factures/situation-paiement-client-facture/situation-paiement-client-facture.component";
import { AvoirFatureComponent } from "./Factures/avoirFacture/avoirFacture.component";
import { DevissComponent } from "./Ventes/Deviss/Deviss.component";
import { CommercialComponent } from "./Ventes/commercial/commercial.component";
import { RechercherArticleComponent } from "./rechercherArticle/rechercherArticle.component";
import { ArticlesRestoreComponent } from "./article/articlesRestore/articlesRestore.component";
import { AcceuilComponent } from './acceuil/acceuil.component';
import { ClientFactureComponent } from "./Factures/clientFacture/clientFacture.component";
import { ClientCamionComponent } from "./camion/clientCamion/clientCamion.component";
import { SituationPaiementClientCamionComponent } from "./camion/situation-paiement-clientCamion/situation-paiement-clientCamion.component";
import { VenteProCamionComponent } from "./camion/vente-proCamion/vente-proCamion.component";
import { AvoirsCamionComponent } from "./camion/avoirsCamion/avoirsCamion.component";
import { VariationStockDepotCamionComponent } from "./camion/variation-stock-depotCamion/variation-stock-depotCamion.component";
import { ArticlesCodeBareComponent } from "./article/articlesCodeBare/articlesCodeBare.component";
import { FlatfileAdapterModule } from "@flatfile/angular";
import { VariationStockDepotJointComponent } from "./stocks/variation-stock-depot-joint/variation-stock-depot-joint.component";
import { CodeBarreComponent } from "./etiquettes/codeBarre/codeBarre.component";
import {SliderModule} from 'primeng/slider';
import {InputNumberModule} from 'primeng/inputnumber';
import { CodeBarrePrintComponent } from "./etiquettes/codeBarrePrint/codeBarrePrint.component";
import { controleStockComponent } from "./stocks/controle-stock/controle-stock.component";
import { ClotureComponent } from "./cloture/cloture.component";
import { PanelModule } from "primeng/panel";
import { CodeBarrePrintEtiquettesComponent } from "./etiquettes/codeBarrePrintEtiquettes/codeBarrePrintEtiquettes.component";
import { FavorisComponent } from "./article/favoris/favoris.component";
import { ArticlesDuplicatComponent } from "./article/articlesDuplicat/articlesDuplicat.component";
import { MsqlSchemaComponent } from "./article/msqlSchema/msqlSchema.component";
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToggleButtonModule } from 'primeng/togglebutton';
// import { TactileAchatComponent } from "./achats/tactileAchat/tactileAchat.component";

// import { CalendarrComponent } from "./calendar/calendarr/calendarr.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    GestionUserComponent,
    TypesComponent,
    ListesComponent,
    ListeDetailComponent,
    ModePaiementComponent,
    ArticlesComponent,
    ArticlesCodeBareComponent,
    ArticlesRestoreComponent,
    BarreCodeComponent,
    SousTypesComponent,
    ClientComponent,
    ClientCamionComponent,
    ClientFactureComponent,
    CommercialComponent,
    AchatComponent,
    // TactileAchatComponent,
    VenteComponent,
    TactileComponent,
    PersonnelComponent,
    FournisseursComponent,
    StockComponent,
    MyFilterPipe,
    SituationVenteComponent,
    AccueilComponent,
    ModifierFactureComponent,
    RendezVousComponent,
    ChargesComponent,
    SituationPaiementComponent,
    SituationAchatComponent,
    SalaireComponent,
    EcheancierComponent,
    AlertStockComponent,
    CalendarComponent,
    VariationStockComponent,
    InitialStockComponent,
    AddStockComponent,
    VenteProComponent,
    VenteProCamionComponent,
    AvoirComponent,
    AvoirsComponent,
    AvoirsCamionComponent,
    AvoirFatureComponent,
    AvoirsAchatComponent,
    FactureComponent,
    SituationFactureComponent,
    SituationStockComponent,
    SituationPaiementVenteComponent,
    SituationPaiementClientComponent,
    SituationPaiementClientCamionComponent,
    SituationPaiementClientFactureComponent,
    SituationPaiementFournisseurComponent,
    SituationPaiementFactureComponent,
    SituationPaiementAchatComponent,
    DevisComponent,
    DevissComponent,
    CryptComponent,
    ParametreComponent,
    StockDepotComponent,
    StockDepotsComponent,
    VariationStockDepotComponent,
    VariationStockDepotJointComponent,
    VariationStockDepotCamionComponent,
    MouvementStockDepotComponent,
    DepotsComponent,
    RechercherArticleComponent,
    AcceuilComponent,
    CodeBarreComponent,
    CodeBarrePrintComponent,
    controleStockComponent,
    ClotureComponent,
    CodeBarrePrintEtiquettesComponent,
    FavorisComponent,
    ArticlesDuplicatComponent,
    MsqlSchemaComponent,
    // CalendarrComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SpinnerModule,
    MenubarModule,
    ButtonModule,
    RadioButtonModule,
    InputTextModule,
    HttpClientModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    CheckboxModule,
    FieldsetModule,
    PaginatorModule,
    ListboxModule,
    JwPaginationModule,
    NgxPaginationModule,
    NgxPrintModule,
    ChartModule,
    SidebarModule,
    ScrollPanelModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    BlockUIModule,
    TooltipModule,
    PanelModule,
    NgxBarcodeModule,
    // RippleModule,
    NgSelectModule,
    DataTablesModule,
    // ConfirmDialogModule,
    // ConfirmationService,
    KeyFilterModule,
    TabViewModule,
    CardModule,
    FileUploadModule,
    InputSwitchModule,
    ColorPickerModule,
    FlatfileAdapterModule,
    SliderModule,
    InputNumberModule,
    AccordionModule,
    OverlayPanelModule,
    ToggleButtonModule
  ],
  providers: [
  { provide: LOCALE_ID, useValue: "fr-FR" },
  { provide: LocationStrategy, useClass: HashLocationStrategy}
],
  bootstrap: [AppComponent],
  
})
export class AppModule {}
