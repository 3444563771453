import { Component, ElementRef,ViewChild, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Depot } from "../../model/depot";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { DataBase } from "src/app/model/dataBase";
import { Article } from "src/app/model/article";
import { Vente } from "src/app/model/vente";
import { DecimalPipe } from "@angular/common";
// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-codeBarrePrintEtiquettes",
  templateUrl: "codeBarrePrintEtiquettes.component.html",
  styleUrls: ["codeBarrePrintEtiquettes.component.scss"],
  providers: [MessageService,DecimalPipe],
})
export class CodeBarrePrintEtiquettesComponent implements OnInit {

  @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
  setFocusQte(){
    setTimeout(() => this.inputQte.nativeElement.focus());
  }
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload: File = null;
  numberColomn: any[];
  numberRow: any[];
  Donnees     : DataBase[];
  Donnee      : DataBase = {};
  Article: Article = {};
  Articles: Article[];  
  tableArticle: Article[];
  idArticleTest: number;
  filteredArticles: Article[];
  tableCommandeVente: Vente = {};
  rechercheArticle: Article = {};
  tableEtiquette: any = [];
  tableEtiquettee: any = [];
  tableCommandeVentes: any = [];
  justifyOptions: any[];
  able: boolean =false;
  barreCodePriceAlign: any={"icon": "pi pi-align-center", "justify": "Center"};
  dataTable       : string = "etiquette";
  selectedDepot   : Depot;
  displayDialog   : boolean;
  dropdownArticle : boolean =false;
  blockedDocument : boolean =false;
  barreCode       : boolean =true;
  barreCodeLabel  : boolean =true;
  barreCodeLabelGras  : boolean =true;
  barreCodeType  : boolean =true;
  barreCodePrice  : boolean =true;
  barreCodePriceGras  : boolean =true;
  showButon: boolean=false; 
  barreCodeFormat: any[]=[];
  index: string;
  selectedOption: string;
  designation: string="";
  barreCodeLabelPaddingB: number;
  nbreRows: number=1;
  nbreColumns: number=1;
  largeur: number;
  hauteur: number;
  widthPage: number;
  heightPage: number;
  EtiquetteV: number;
  EtiquetteH: number;
  wPage: string;
  hPage: string; 
  pageMarginT: number=5;
  pageMarginL: number=5;
  pageWidth: number=210;
  pageHeight: number=297;
  optionsBarreCode: any[];
  optionsBarreCodeLabelGras: any[];
  optionsBarreCodeType: any[];
  totalQte: number;
  tiquetteMarginR: number=2;
  tiquetteMarginB: number=2;
  tiquetteRadius: number=2;
  sizeDesignation: number=12;
  barreCodePriceSize: number=12;
  Xquantite: number;
  divWidth: number=38;
  divHeight: number=22;
  CodeBarreTaille: number=10;
  CodeBarreHeight: number=22;
  CodeBarrevWidth: number=1;
  // wPage: string;
  // hPage: string;

  // numberColomns: any[];
  selectedFile = null;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 5;
  fontSize = 20;
  background = '#ffffff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginLeftPx = '';
  marginBottomPx = '';
  marginRight = 0;
  marginRightPx = '13px';
  elementType = 'SVG';
  value = '6110085048829';
  format = 'CODE128';
  // format = 'EAN13';
  lineColor = '#000000';
  width = 1;
  height = 30;
  displayValue = true;

  codeList: string[] = [
    '', 'CODE128',
    'CODE128A', 'CODE128B', 'CODE128C',
    'UPC', 'EAN8', 'EAN5', 'EAN2',
    'CODE39',
    'ITF14',
    'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110',
    'pharmacode',
    'codabar'
  ];

  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private router: Router) {
      this.justifyOptions = [
        {icon: 'pi pi-align-left', justify: 'Left'},
        {icon: 'pi pi-align-center', justify: 'Center'},
        {icon: 'pi pi-align-right', justify: 'Right'}
    ];
    this.optionsBarreCode = [{label: 'On', value: true},{label: 'Off', value: false}];
    this.optionsBarreCodeLabelGras = [{label: 'Normal', value: false}, {label: 'Gras', value: true}];
    this.optionsBarreCodeType = [{label: 'Adhesive A4', value: true}, {label: 'Etiquette', value: false}];
    }
  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.        currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.donnee();
    this.donneeFormat();
    this.article();
  }
  donneeFormat(){
    this.Donnee.action = "SelectEtiquette";
    this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
    // this.optionsBarreCodeType = [{label: 'Adhesive A4', value: true}, {label: 'Etiquette', value: false}];
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.optionsBarreCodeType=x));
  }
  article() {
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (
        this.Articles = x,this.tableArticle = x,
        console.log(x),
        this.filteredArticles = x
        ));
  }
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
    this.tableCommandeVente.quantite=65;
  }
  findPrix(idArticle,colone) {  
  if (idArticle!=undefined || this.tableCommandeVente.autocompleteCodeBarre!=undefined){
    this.dropdownArticle=false;

    if(typeof idArticle =="string" && colone=="codeBarre-"){
      idArticle=this.tableCommandeVente.autocomplete;
      colone="codeBarre";        
    }else{    
      colone="codeBarre-";
    }
    
    if (idArticle && this.idArticleTest!=idArticle){
      if (this.idArticleTest!=idArticle){
        this.idArticleTest=idArticle;
        this.setFocusQte();
      }
    }
      this.tableCommandeVente.stock             = 0;
      this.tableCommandeVente.designation       = idArticle.designation;
      this.tableCommandeVente.prixVente         = idArticle.prixVente;
      this.tableCommandeVente.idArticle         = idArticle.id;

    if(colone=="codeBarre"){
      this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
      this.tableCommandeVente.autocomplete = this.rechercheArticle; 
    }else if(colone=="designation"){
      this.rechercheArticle = this.Articles.find((x) => x.designation == idArticle.designation);
      this.tableCommandeVente.autocomplete = idArticle; 
    }else {
      this.rechercheArticle = this.Articles.find((x) => x.id === idArticle.idArticle*1);
      this.tableCommandeVente.autocomplete = idArticle; 
    }
      // this.tableCommandeVente.idArticle         = this.rechercheArticle.idArticle;               
      // this.tableCommandeVente.designation       = this.rechercheArticle.designation;
    }
}
addArticle(data) {
  // console.log(data);
  if(data.idArticle && data.quantite){
    if(this.showButon==false){
      if((data.quantite*1+this.totalQte*1)>(this.EtiquetteH*1*this.EtiquetteV*1)){
        this.messageService.add({severity:'warn', summary: 'Impression', detail: "Nombre d'etiquette est supperieur Etiquette => " + this.EtiquetteH*1*this.EtiquetteV*1});
      }else{
        if(this.tableCommandeVente.autocomplete){
          data.codeBarre=this.tableCommandeVente.autocomplete.codeBarre;
          data.reference=this.tableCommandeVente.autocomplete.reference;
        }
        if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
          this.tableCommandeVentes.push(data);
          this.showButon = false;
        } else {
          this.messageService.add({severity:'warn', summary: 'Impression', detail: "Entrer un Article !"});
        }
        // this.desablePrint=true;
        // this.setFocusArticle();
        this.totalQantite();          
      }
    }else{
      alert(this.totalQte*1-this.Xquantite*1+this.tableCommandeVente.quantite*1);
        if((this.totalQte*1-this.Xquantite*1+this.tableCommandeVente.quantite*1)>(this.EtiquetteH*1*this.EtiquetteV*1)){
          this.messageService.add({severity:'warn', summary: 'Impression', detail: "Nombre d'etiquette est supperieur Etiquette => " + this.EtiquetteH*1*this.EtiquetteV*1});
        }else{
          this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
          this.tableCommandeVentes[this.index].designation      = data.designation;
          this.tableCommandeVentes[this.index].quantite         = data.quantite;
          this.showButon = false;
          this.totalQantite(); 
        }
    }

  }else{
    this.messageService.add({severity:'warn', summary: 'Impression', detail: "Article ou Quantité ?"});
  }
}
totalQantite() {
  // let totalQte = 0;
  // for (let data of this.tableCommandeVentes) {
  //   totalQte += data.quantite * 1;
  // }
  // this.totalQte = totalQte;
  this.tableEtiquette=[];
  this.tableCommandeVentes.forEach((row,index)=>{
    for (let i = 0; i < row.quantite; i++) {
      this.tableEtiquette.push(row);
    }
  });
}
PrintElem(div){
    var mywindow = window.open('', 'PRINT', 'height=800,width=1200');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');    
    mywindow.document.write('<link rel="stylesheet" href="css/midday_receipt.css" type="text/css" />');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<div style="display: flex;flex-wrap: wrap;width: '+this.pageWidth+'mm !important; margin-top:'+this.pageMarginT+'mm !important;margin-left: '+this.pageMarginL+'mm !important; ">');
    mywindow.document.write(document.getElementById(div).innerHTML);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    // setTimeout(function(){mywindow.print();},3000);
    mywindow.print();
    mywindow.close();  
    // return true;
}
changeBarreCodeFormat(articl,type,div){
  // let ArticleRecherche = this.Articles.find((x) => x.idArticle == id);
  // console.log(this.Article);
  var nbre;
  var qte = prompt("Nombre de Code Bare ? ");
  if (qte == null || qte == "") {
  // txt = "User cancelled the prompt.";
  } else {
    nbre = qte ;
  }

  this.tableEtiquette=[];
  for (let i = 0; i < nbre; i++) {
    this.tableEtiquette.push(articl);
  }
  let rechercheDepot = this.Donnees.find((x) => x.designation == type);
  if(rechercheDepot){
    this.newDonnee = false;
    this.pageWidth=rechercheDepot.pageWidth;
    this.pageHeight=rechercheDepot.pageHeight;
    this.designation=rechercheDepot.designation;
    this.nbreColumns=rechercheDepot.nbreColumns;
    this.nbreRows=rechercheDepot.nbreRows;
    this.tiquetteMarginB=rechercheDepot.tiquetteMarginB;
    this.tiquetteMarginR=rechercheDepot.tiquetteMarginR;
    this.divHeight=rechercheDepot.divHeight;
    this.divWidth=rechercheDepot.divWidth;
    this.pageMarginT=rechercheDepot.pageMarginT;
    this.pageMarginL=rechercheDepot.pageMarginL;
    this.sizeDesignation=rechercheDepot.sizeDesignation;
    this.barreCodePriceSize=rechercheDepot.barreCodePriceSize;
    this.barreCode=rechercheDepot.barreCode;
    this.barreCodeLabel=rechercheDepot.barreCodeLabel;
    this.barreCodeLabelGras=rechercheDepot.barreCodeLabelGras;
    this.barreCodePrice=rechercheDepot.barreCodePrice;
    this.barreCodePriceGras=rechercheDepot.barreCodePriceGras;

    this.CodeBarrevWidth=rechercheDepot.CodeBarrevWidth;
    this.CodeBarreHeight=rechercheDepot.CodeBarreHeight;
    this.CodeBarreTaille=rechercheDepot.CodeBarreTaille;
    this.barreCodePriceAlign = this.justifyOptions.find((x) => x.justify == rechercheDepot.barreCodePriceAlign);
    if(rechercheDepot.barreCode==1){
      this.barreCode=true;
    }else{
      this.barreCode=false;
    }
    if(rechercheDepot.barreCodeLabel==1){
      this.barreCodeLabel=true;
    }else{
      this.barreCodeLabel=false;
    }
    if(rechercheDepot.barreCodeLabelGras==1){
      this.barreCodeLabelGras=true;
    }else{
      this.barreCodeLabelGras=false;
    }
    if(rechercheDepot.barreCodePrice==1){
      this.barreCodePrice=true;
    }else{
      this.barreCodePrice=false;
    }
    if(rechercheDepot.barreCodePriceGras==1){
      this.barreCodePriceGras=true;
    }else{
      this.barreCodePriceGras=false;
    }
  }
}
transformDecimal(num) {
  return this._decimalPipe.transform(num, "1.2-2");
}
  conteHorizontal(element){
    // A4	210 x 297 mm / largeur minimale Code Barre de 29.83 mm
    //1 Millimètre est égal à 3.7795 Pixel.
    this.marginRight=0;
    this.marginLeft=10;
    this.marginLeftPx=this.marginLeft +'px';
    this.largeur=210-this.marginLeft/3.77795*element;
    // 7.2=>largeur+MarginCodeBarre+MarginPage
    this.widthPage=this.largeur/element/29;
    this.wPage=this.widthPage+"mm";
  }
  conteVertical(element){
    this.marginBottom=10;
    this.marginBottomPx=this.marginBottom +'px';
    this.hauteur=297;//-this.marginBottom/3.77795*element*1.9
    this.heightPage=this.hauteur/element;
    this.hPage=this.heightPage+"mm";
  }
  numberColomns(n: number): Array<number> {
    return Array(n);
  }
  changeBarreCodeType(){
    console.log(this.Donnee);
    if(!this.Donnee.id){
      if(this.barreCodeType==true){
        this.pageWidth=210;
        this.pageHeight=297;
        this.designation="";
        this.nbreColumns=5;
        this.nbreRows=13;
        this.pageMarginT=5;
        this.pageMarginL=5;
        this.divHeight=22;
        this.divWidth=38;
        this.tiquetteMarginB=2;
        this.tiquetteMarginR=2;
        this.barreCodeLabel=true;
        this.barreCodeLabelGras=true;
        this.barreCode=true;
        this.CodeBarrevWidth=1;
        this.CodeBarreHeight=22;
        this.CodeBarreTaille=10;
        this.sizeDesignation=12;
        this.barreCodePriceSize=12;
        this.barreCodePrice=true;
        this.barreCodePriceGras=true;
        this.barreCodePriceAlign=this.justifyOptions.find((x) => x.justify == "Center");
        this.Donnee={};
      }else{
        this.pageWidth=50;        
        this.pageHeight=35;
        this.designation="";
        this.nbreColumns=1;
        this.nbreRows=1;
        this.pageMarginT=0;
        this.pageMarginL=0;
        this.divHeight=22;
        this.divWidth=38;
        this.tiquetteMarginB=0;
        this.tiquetteMarginR=0;
        this.barreCodeLabel=true;
        this.barreCodeLabelGras=true;
        this.barreCode=true;
        this.CodeBarrevWidth=1;
        this.CodeBarreHeight=22;
        this.CodeBarreTaille=10;
        this.sizeDesignation=12;
        this.barreCodePriceSize=12;
        this.barreCodePrice=true;
        this.barreCodePriceGras=true;
        this.barreCodePriceAlign=this.justifyOptions.find((x) => x.justify == "Center");
        this.Donnee={};
      }      
    }   
    console.log(this.barreCodeType);
  }
  donnee() {
    this.Donnee.action = "Select";
    this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x));
  }
  save() {
    if (this.designation == "") {
      this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: 'Vide'});
    } else {
      if (this.Donnee.id) {
        // this.Donnee.divWidth=this.;
        this.Donnee.pageWidth=this.pageWidth;
        this.Donnee.pageHeight=this.pageHeight;
        this.Donnee.designation=this.designation;
        this.Donnee.nbreColumns=this.nbreColumns;
        this.Donnee.nbreRows=this.nbreRows;
        this.Donnee.tiquetteMarginB=this.tiquetteMarginB;
        this.Donnee.tiquetteMarginR=this.tiquetteMarginR;
        this.Donnee.divHeight=this.divHeight;
        this.Donnee.divWidth=this.divWidth;
        this.Donnee.pageMarginT=this.pageMarginT;
        this.Donnee.pageMarginL=this.pageMarginL;
        this.Donnee.barreCodeLabel=this.barreCodeLabel;
        this.Donnee.barreCodeLabelGras=this.barreCodeLabelGras;
        this.Donnee.barreCodeType=this.barreCodeType;
        this.Donnee.barreCode=this.barreCode;
        this.Donnee.CodeBarrevWidth=this.CodeBarrevWidth;
        this.Donnee.CodeBarreHeight=this.CodeBarreHeight;
        this.Donnee.CodeBarreTaille=this.CodeBarreTaille;
        this.Donnee.sizeDesignation=this.sizeDesignation;
        this.Donnee.barreCodePriceSize=this.barreCodePriceSize;
        this.Donnee.barreCodePrice=this.barreCodePrice;
        this.Donnee.barreCodePriceGras=this.barreCodePriceGras;
        this.Donnee.barreCodePriceAlign=this.barreCodePriceAlign.justify;
        console.log(this.Donnee);
        this.Donnee.action = "Update";
        this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.data
        .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else { 
        this.Donnee.pageWidth=this.pageWidth;
        this.Donnee.pageHeight=this.pageHeight;
        this.Donnee.designation=this.designation;
        this.Donnee.nbreColumns=this.nbreColumns;
        this.Donnee.nbreRows=this.nbreRows;
        this.Donnee.tiquetteMarginB=this.tiquetteMarginB;
        this.Donnee.tiquetteMarginR=this.tiquetteMarginR;
        this.Donnee.divHeight=this.divHeight;
        this.Donnee.divWidth=this.divWidth;
        this.Donnee.pageMarginT=this.pageMarginT;
        this.Donnee.pageMarginL=this.pageMarginL;
        this.Donnee.barreCodeLabel=this.barreCodeLabel;
        this.Donnee.barreCodeLabelGras=this.barreCodeLabelGras;
        this.Donnee.barreCodeType=this.barreCodeType;
        this.Donnee.barreCode=this.barreCode;
        this.Donnee.CodeBarrevWidth=this.CodeBarrevWidth;
        this.Donnee.CodeBarreHeight=this.CodeBarreHeight;
        this.Donnee.CodeBarreTaille=this.CodeBarreTaille;
        this.Donnee.sizeDesignation=this.sizeDesignation;
        this.Donnee.barreCodePriceSize=this.barreCodePriceSize;
        this.Donnee.barreCodePrice=this.barreCodePrice;
        this.Donnee.barreCodePriceGras=this.barreCodePriceGras;
        this.Donnee.barreCodePriceAlign=this.barreCodePriceAlign.justify;
        this.Donnee.action = "Insert";
        this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  showConfirm() {
      // this.messageService.clear();
      this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Vous ete sur de suprimmer?', detail:'Confirmation'});
  }
  clear() {
      this.messageService.clear();
  }
  onConfirm() {
      this.delete();
    this.messageService.clear('c');
  }

  onReject() {
      this.messageService.clear('c');
  }
  delete() {
    // if (confirm("êtes vous sure?")) {
    if (confirm("êtes vous sure?")) {  
      this.Donnee.dateNow = new Date().toISOString().split("T")[0]; 
      this.Donnee.action = "Delete";
      this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    // }
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Donnee = {};
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/api/uploads/default.png";
    this.Donnee = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    // this.displayDialog = true;
    this.newDonnee = false;
    this.pageWidth=event.data.pageWidth;
    this.pageHeight=event.data.pageHeight;
    this.designation=event.data.designation;
    this.nbreColumns=event.data.nbreColumns;
    this.nbreRows=event.data.nbreRows;
    this.tiquetteMarginB=event.data.tiquetteMarginB;
    this.tiquetteMarginR=event.data.tiquetteMarginR;
    this.divHeight=event.data.divHeight;
    this.divWidth=event.data.divWidth;
    this.pageMarginT=event.data.pageMarginT;
    this.pageMarginL=event.data.pageMarginL;
    this.sizeDesignation=event.data.sizeDesignation;
    this.barreCodePriceSize=event.data.barreCodePriceSize;

    if(event.data.barreCode==1){
      this.barreCode=true;
    }else{
      this.barreCode=false;
    }
    if(event.data.barreCodeLabel==1){
      this.barreCodeLabel=true;
    }else{
      this.barreCodeLabel=false;
    }
    if(event.data.barreCodeLabelGras==1){
      this.barreCodeLabelGras=true;
    }else{
      this.barreCodeLabelGras=false;
    }
    if(event.data.barreCodeType==1){
      this.barreCodeType=true;
    }else{
      this.barreCodeType=false;
    }
    if(event.data.barreCodePrice==1){
      this.barreCodePrice=true;
    }else{
      this.barreCodePrice=false;
    }
    if(event.data.barreCodePriceGras==1){
      this.barreCodePriceGras=true;
    }else{
      this.barreCodePriceGras=false;
    }
    this.CodeBarrevWidth=event.data.CodeBarrevWidth;
    this.CodeBarreHeight=event.data.CodeBarreHeight;
    this.CodeBarreTaille=event.data.CodeBarreTaille;
    this.barreCodePriceAlign = this.justifyOptions.find((x) => x.justify == event.data.barreCodePriceAlign);
    // this.barreCodeLabel=event.data.barreCodeLabel;
    // this.barreCodeLabelGras=event.data.barreCodeLabelGras;barreCodePriceAlign
    // this.barreCodePrice=event.data.barreCodePrice;
    // this.barreCodePriceGras=event.data.barreCodePriceGras;
  
    this.Donnee = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: DataBase): DataBase {
    let Depot = {};
    for (let prop in c) {
      Depot[prop] = c[prop];
    }
    return Depot;
  }
  initialiser(){
    this.designation="";
    this.nbreColumns=1;
    this.nbreRows=1;
    this.pageMarginT=5;
    this.pageMarginL=5;
    this.divHeight=22;
    this.divWidth=38;
    this.tiquetteMarginB=2;
    this.tiquetteMarginR=2;
    this.barreCodeLabel=true;
    this.barreCodeLabelGras=true;
    this.barreCodeType=true;
    this.barreCode=true;
    this.CodeBarrevWidth=1;
    this.CodeBarreHeight=22;
    this.CodeBarreTaille=10;
    this.sizeDesignation=12;
    this.barreCodePriceSize=12;
    this.barreCodePrice=true;
    this.barreCodePriceGras=true;
    this.barreCodePriceAlign=this.justifyOptions.find((x) => x.justify == "Center");
    this.Donnee={};
  }
}
