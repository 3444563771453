import { Component, ElementRef,ViewChild, OnInit,PipeTransform, Pipe } from "@angular/core";
import { DataService } from "../../data.service";
import { Article } from "../../model/article";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DecimalPipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthenticationService } from "../../authentication.service";
import * as $ from 'jquery';
import { DataBase } from "../../model/dataBase";
import { stringify } from "querystring";
// import {
//   trigger,
//   state,

//   style,
//   transition,
//   animate,
// } from "@angular/animations";

@Component({
  selector: "app-codeBarrePrintEtiquette",
  templateUrl: "codeBarrePrintEtiquette.component.html",
  styleUrls: ["codeBarrePrintEtiquette.component.scss"],
  providers: [ConfirmationService,DecimalPipe,MessageService],
})
export class CodeBarrePrintEtiquetteComponent implements OnInit {
  //----------------------Début Parametre CODE BARRE
  elementType = 'svg';
  value = '6933256621283';
  // format = 'EAN13';
  // lineColor = '#000000';
  // width = 1.5;
  // height = 50;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '',
    'CODE128',
    'CODE128A',
    'CODE128B',
    'CODE128C',
    'UPC',
    'EAN8',
    'EAN5',
    'EAN13',
    'EAN12',
    'EAN2',
    'CODE39',
    'ITF14',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'pharmacode',
    'codabar',
  ];
  //----------------------Fin Parametre CODE BARRE
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/api/api/uploads/default.png";
  fileToUpload: File = null;
  // activite: string = "Service";
  Article: Article = {};
  ArticleFind: Article = {};
  tableEtiquette: any = [];
  ArticleRecherche: Article = {};
  Articles: Article[];
  tableArticle: Article[];
  selectedArticle: Article;
  displayDialog: boolean;
  displayDialogCodeBarre: boolean = false;
  selectedOption: string;
  selectedType: number;
  blockedDocument: boolean = false;
  able: boolean =false;  
  pageWidth: number;
  pageHeight: number;
  designation: string;
  codeBarre: string;
  type: string;
  dateNow?: string;
  file?: string;  
  Donnees     : DataBase[];
  Donnee      : DataBase = {};
  dataTable   : string = "etiquette";
  dropdownArticle: boolean = true;
  requetteCommandeAchats: any = [];
  filteredArticles: any[];
  rechercheArticle: Article = {};
  tableCommandeAchats: any = [];  
  barreCodeType: any[]=[];
  optionsBarreCodeType: any[];
  commandeDetail: string;
  formatBarreCode: string;
  showButon: boolean =false;
  verifie: boolean = true;
  swichCodeBarre: boolean = false;
  quantite:number=90;

  divWidth: number;
  divHeight: number;
  pageMarginT: number;
  pageMarginL: number;
  pagePaddingT: number;
  pagePaddingL: number;
  wPage: string;
  hPage: string;
  
  barreCodeLabelPaddingB: number;
  nbreRows: number;
  nbreColumns: number;
  hauteur: number;
  optionsBarreCode: any[];
  optionsBarreCodeLabelGras: any[];
  barreCodePriceSize: number;
  CodeBarreHeight: number;
  CodeBarrevWidth: number;
  barreCodePriceAlign: any;
  barreCode       : boolean ;
  barreCodeLabel  : boolean ;
  barreCodeLabelGras  : boolean ;
  barreCodePrice  : boolean ;
  barreCodePriceGras  : boolean ;
  tiquetteMarginR: number;
  tiquetteMarginB: number;  
  sizeDesignation: number=12;
  CodeBarreTaille: number=10;

  constructor(
    private data: DataService, 
    private http: HttpClient,
    public AuthenticationService: AuthenticationService,
    private _decimalPipe: DecimalPipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ) {}

      // num:number = 70;
  ngOnInit() {
    this.dateNow = new Date().toISOString().split("T")[0];
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    // this.optionsBarreCodeType = [{label: 'Adhesive A4', value: true}, {label: 'Etiquette', value: false}];
    this.article();
    this.donnee();
    // $('#elemId').val("8003511428570");
  }
  donnee() {
    this.Donnee.action = "SelectEtiquette";
    this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
    // this.optionsBarreCodeType = [{label: 'Adhesive A4', value: true}, {label: 'Etiquette', value: false}];
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x,this.optionsBarreCodeType=x));
  }
  transform(value) {
    return (new Array(value)).fill(1);
  }
  // getArticles() {
  // 	this.data.getArticles().toPromise().then((x: Article[]) => ((this.Articles = x), (this.tableArticle = x)));
  // }
  article() {
    // this.Article.action = "SelectStockArticle";
    // this.Article.action = "RecapDepot";
    this.Article.action = "Select";
    this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      // .stock(this.Article)
      // .stockDepot(this.Article)
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x, this.tableArticle = x));
  }

  
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
      this.setFocusDesignation();
  }
  findPrix(idArticle,typee) {
    // console.log(idArticle);
    if (idArticle!=undefined){
      this.dropdownArticle=false;
      // if(typee=="codeBarre"){
      //   this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
      // }else if(typee=="designation"){
      //   this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
      // }else {
        this.rechercheArticle = this.Articles.find((x) => x.id === idArticle);
      // }
      
      // this.rechercheArticle = this.Articles.find((x) => x.id === idArticle);
      // let recherche = this.tableCommandeAchats.filter((x) => x.idArticle == idArticle.idArticle);
      // this.requetteCommandeAchats = recherche;
    }
  }
  changeBarreCodeType(articl,type,div){
    // let ArticleRecherche = this.Articles.find((x) => x.idArticle == id);
    // console.log(this.Article);
    var nbre;
    var qte = prompt("Nombre de Code Bare ? ");
    if (qte == null || qte == "") {
    // txt = "User cancelled the prompt.";
    } else {
      nbre = qte ;
    }
    // this.ArticleFind=articl;
    this.tableEtiquette=[];
    for (let i = 0; i < nbre; i++) {
      this.tableEtiquette.push(articl);
    }
    console.log(this.tableEtiquette);
    let rechercheDepot = this.Donnees.find((x) => x.designation == type);
    console.log(rechercheDepot);
    if(rechercheDepot){
    this.pageWidth=rechercheDepot.pageWidth;
    this.pageHeight=rechercheDepot.pageHeight;
    this.designation=rechercheDepot.designation;
    this.nbreColumns=rechercheDepot.nbreColumns;
    this.nbreRows=rechercheDepot.nbreRows;
    this.tiquetteMarginB=rechercheDepot.tiquetteMarginB;
    this.tiquetteMarginR=rechercheDepot.tiquetteMarginR;
    this.divHeight=rechercheDepot.divHeight;
    this.divWidth=rechercheDepot.divWidth;
    this.pageMarginT=rechercheDepot.pageMarginT;
    this.pageMarginL=rechercheDepot.pageMarginL;
    this.sizeDesignation=rechercheDepot.sizeDesignation;
    this.barreCodePriceSize=rechercheDepot.barreCodePriceSize;



    if(rechercheDepot.barreCode==1){
      this.barreCode=true;
    }else{
      this.barreCode=false;
    }
    if(rechercheDepot.barreCodeLabel==1){
      this.barreCodeLabel=true;
    }else{
      this.barreCodeLabel=false;
    }
    if(rechercheDepot.barreCodeLabelGras==1){
      this.barreCodeLabelGras=true;
    }else{
      this.barreCodeLabelGras=false;
    }
    if(rechercheDepot.barreCodePrice==1){
      this.barreCodePrice=true;
    }else{
      this.barreCodePrice=false;
    }
    if(rechercheDepot.barreCodePriceGras==1){
      this.barreCodePriceGras=true;
    }else{
      this.barreCodePriceGras=false;
    }
    
      this.barreCodePriceAlign = rechercheDepot.barreCodePriceAlign;
      console.log(this.barreCodePriceAlign);
    }
    console.log(div);
    console.log(type);
    var mywindow = window.open('', 'PRINT', 'height=800,width=1200');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');    
    mywindow.document.write('<link rel="stylesheet" href="css/midday_receipt.css" type="text/css" />');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<div style="display: flex;flex-wrap: wrap;width: '+this.pageWidth+'mm !important; margin-top:'+this.pageMarginT+'mm !important;margin-left: '+this.pageMarginL+'mm !important; ">');
    mywindow.document.write(document.getElementById(div).innerHTML);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    // setTimeout(function(){mywindow.print();},3000);
    mywindow.print();
    mywindow.close();
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  // && this.Article.idType && this.Article.tva
  save() {
    if(this.Article.designation && this.Article.prixVente   ){
      this.blockedDocument=true;
      if (this.Article.id) {
        this.Article.action           = "Update";
        this.Article.commandeDetail   = this.commandeDetail;
        this.Article.DBase            = this.AuthenticationService.currentUserValue.DBase;
        this.Article.dateNow          = this.dateNow;
        this.Article.verifie          = this.verifie;
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
            }
          });
      } else {
        this.Article.action           = "Insert";
        this.Article.DBase            = this.AuthenticationService.currentUserValue.DBase;
        this.Article.commandeDetail   = this.commandeDetail;
        this.Article.verifie          = this.verifie;
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
              // this.displayDialog = false;
            }
          });
      }
    }else{
      alert("Vous avez oublier un champ !!!")
    }
    
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Article.action = "Delete";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Article.dateNow = new Date().toISOString().split("T")[0]; 
      // console.log(this.Article);
      this.data
        .article(this.Article)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.article();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }
  onUpload() {
    // this.Article.chemin = this.myUrl + "/api/api/";
    // alert(this.Type.type);
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .posteFileArticle(
        this.Article.id,
        this.Article.designation,
        this.Article.DBase,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: data.message});
        this.article();
        this.displayDialog = false;
      });
  }
  close() {
    this.displayDialog = false;
    this.Article = {};
    this.file="";
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.file="";
   
    this.newDonnee = true;
    // this.Article.activite = 'Service';
    this.Article = {};
    this.Article.activite=false;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.newDonnee = false;
    this.file="";
    if (event.data.images == null) {
      var leet = this.myUrl + "/API/api/uploads/default.png";
    }else{
      var leet = this.myUrl + "/API/api/uploads/images/" + event.data.images;     
    }
    this.imageUrl = leet;
    this.Article = this.cloneDonnee(event.data);
    // console.log(event.data);
    // console.log(event.data);

    this.Article.tva = +event.data.tva; 
    this.Article.idType = +event.data.idType; 
    this.Article.forfait = +event.data.forfait; 
    this.Article.activite = +event.data.activite; 
    // [bc-format]='CODE128','CODE128A','CODE128B','CODE128C','UPC','EAN8','EAN5',
    // 'EAN13','EAN12','EAN2','CODE39','ITF14','MSI',
    // 'MSI10','MSI11','MSI1010','MSI1110','pharmacode','codabar',
    // console.log(event.data);
    if(event.data.codeBarre.length==13){
      this.formatBarreCode='EAN13';
    }else if(event.data.codeBarre.length==11){
      this.formatBarreCode='UPC'
    }else if(event.data.codeBarre.length==8){
      this.formatBarreCode='EAN8'
    }else{
      this.formatBarreCode='CODE128'
    }
    this.displayDialog = true;
  }
  cloneDonnee(c: Article): Article {
    let Article = {};
    for (let prop in c) {
      Article[prop] = c[prop];
    }
    return Article;
  }
}
