import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import * as XLSX from "xlsx";
import { Vente } from "../../model/vente";
import { Client } from "../../model/client";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';
import { Avoir } from "../../model/avoir";
import { ModePaiement } from "src/app/model/modePaiement";

@Component({
  selector: "app-situation-paiement-client-facture.component",
  templateUrl: "situation-paiement-client-facture.component.html",
  styleUrls: ["situation-paiement-client-facture.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementClientFactureComponent implements OnInit {
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  Avoir: Avoir = {};
  Avoirs: Avoir[];
  tableDetailAvoirs: Avoir[];
  tableRecapAvoirs: Avoir[];
  tableRecapeAvoirs: Avoir[];
  tableRecapeAchats: Vente[];
  // recherchePaiement: Paiement = {};
  tableRecapPaiements: Paiement [];
  tableClientPaiements: Vente [];
  tableClientPaiementss: Vente [];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailsVentes: Vente[];
  tableDetailVentes: Vente[];
  tableCommandeDetail: Vente[];
  tableDetailsVentesRequette: Vente[];
  tableDetailPaiements: Paiement[];
  Cheques: Paiement[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];  
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  blockedDocument: boolean;
  displayDialog: boolean;
  displayDialogHistorique: boolean;
  displayDialogDetailBon: boolean;
  displayDialogDetailAvoir: boolean;
  displaycheque: boolean;
  francais: boolean =false;
  langueDirection: string ="ltr";
  langueLbl: string ="العربية";
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  nom: string;
  nomAr: string;
  nomFilter: string;
  nomArFilter: string;
  nomm: string;
  dateD: any ;
  totalMontant: number;
  totaldetailBon: number;
  totalQte: number;
  total: number;
  montant: number;
  avance: number;
  avoir: number;
  totalCheque: number;
  reste: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  nomClient:string;
  dataTable:string;
  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.fournisseur();
    // this.client();
    this.vente();
    this.venteDetail();
    this.venteRecap();
    this.paiementDetail();
    this.modePaiement();
    // this.paiementDetailClient();
    this.Paiement.dateReglement = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance = new Date().toISOString().split("T")[0];
  }
  // client() {
  //   this.Client.action = "Select";
  //   this.data
  //     .client(this.Client)
  //     .toPromise()
  //     .then((x: Client[]) => (this.Clients = x));
  // }
  venteDetail() {
    this.dataTable="facture";
    this.Vente.action = "SelectDetail";
		this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.tableDetailVentes = x,console.log(x)));
  }

  vente() {
    this.dataTable="paiementFacture";
    this.Vente.action = "RecapByClient";
		this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
    .donnee(this.dataTable,this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          this.Ventes = x, 
          this.tableVentes = x,
          this.paiementRecap()
        )
      );
  }
  paiementRecap() {
    this.dataTable="paiementFacture";
    this.Paiement.action = "Recap";
		this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.avoirRecap()));
  }
  avoirRecap() {
    this.dataTable="avoirFacture";
    this.Avoir.action = "SelectRecapByIdClient";
		this.Avoir.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
    .donnee(this.dataTable,this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirs = x,
        this.tablePaiement()));
  }

  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avance = 0;
            this.tableVentes[index].reste = this.tableVentes[index].total*1.2;  
          }else{
          this.tableVentes[index].avance = updateItem.montant;
          this.tableVentes[index].reste = this.tableVentes[index].total*1.2-updateItem.montant;      
          }

        });
    } 
    this.tableAvoir();
}
  tableAvoir(){
    if (this.tableRecapAvoirs.length!=undefined){
      // console.log(this.tableRecapAvoirs);
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirs.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avoir = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].avoir = updateItem.montant;
          this.tableVentes[index].reste += -updateItem.montant;      
          }
        }); 
    }
    this.filter(this.nomFilter,'nom');
  }

  filter(data, colone) {
    if (this.Client.nom===undefined){
      this.Client.nom='';
    }
    if (data===undefined){
      data='';
    }
    let recherche;
    if (colone=="idClient"){
       recherche = this.Ventes.filter((x) => x.idClient.toString().includes(data));
    }else{
      recherche = this.Ventes.filter(
      (x) =>
        x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      ); 
    }
    // console.log(recherche);
    this.tableVentes = recherche;
    this.totalCommande();  
  }

  totalCommande() {
    // console.log(this.tableVentes);
    this.total = 0;
    this.avance = 0;
    this.avoir = 0;
    this.totalCheque = 0;
    this.reste = 0;
    this.total = this.tableVentes
      .map((t) => (t.total) * 1)
      .reduce((a, value) => a + value, 0);
    this.avance = this.tableVentes
      .map((t) => (t.avance) * 1)
      .reduce((a, value) => a + value, 0);
    this.avoir = this.tableVentes
      .map((t) => (t.avoir) * 1)
      .reduce((a, value) => a + value, 0);
    this.reste = this.tableVentes
      .map((t) => (t.reste) * 1)
      .reduce((a, value) => a + value, 0);
    this.totalCheque = this.Cheques
      .map((t) => (t.montant) * 1)
      .reduce((a, value) => a + value, 0);
  } 

  paiementDetail() {
    this.dataTable="paiementFacture";
    this.Paiement.action = "Select";
		this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        this.Cheques =x.filter((x) => x.modePaiement != 'Espèce'),
        this.filter(this.nomClient, 'nom')));
  }

  venteRecap() {
    this.dataTable="facture";
    this.Vente.action = "SelectDetailPaiment";
		this.Vente.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.tableDetailsVentes = x),
          this.paiementDetailClient()
        )
      );
  }

  paiementDetailClient() {
    this.dataTable="paiementFacture";
    this.Paiement.action = "Detail";
		this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Paiement)
      .toPromise()
      .then((x: Vente[]) => (
        this.tableClientPaiements = x,
        this.tableClientPaiementss = x,
        this.avoirRecape()
      ));
  }

  avoirRecape() {
    // alert('eee');
    this.dataTable="avoirFacture";
    this.Avoir.action = "Select";
		this.Avoir.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapeAvoirs = x,
        this.tablePaiementDetail()
      ));
  }

  tablePaiementDetail(){
    if (this.tableDetailsVentes){
        this.tableDetailsVentesRequette = this.tableDetailsVentes;
        if (this.tableClientPaiements){
          this.tableClientPaiements.forEach((row ,index )=>{
          this.tableDetailsVentesRequette.push(row);
          })
        }
        if (this.tableRecapeAvoirs){
          this.tableRecapeAvoirs.forEach((row ,index )=>{
          this.tableDetailsVentesRequette.push(row);
          })
        }        
        if (this.tableRecapeAchats){
          this.tableRecapeAchats.forEach((row ,index )=>{
          this.tableDetailsVentesRequette.push(row);
          })
        }
        this.tableDetailsVentesRequette.sort((b, a) => new Date(b.dateCommande).getTime() - new Date(a.dateCommande).getTime());
        console.log(this.tableDetailsVentesRequette);
        this.filterPaiement();
    }
}
filterPaiement(){
  if(this.tableClientPaiements){
    let rechercheP = this.tableClientPaiements.filter((x) => x.idClient==this.idClient ); 
    this.tableClientPaiementss = rechercheP;    
  }
  this.avoirDetail();
}

avoirDetail() {
  // alert('eee');
  this.dataTable="avoirFacture";
  this.Avoir.action = "Detail";
  this.Avoir.DBase = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .donnee(this.dataTable,this.Avoir)
    .toPromise()
    .then((x: Avoir[]) => (
      this.tableDetailAvoirs = x
    ));
}

changeLangue(){
  // alert(this.francais); 
  if(this.francais==true){
    this.francais=false;
    this.langueDirection="ltr"
    this.langueLbl="العربية"
  }else{
    this.francais=true;
    this.langueDirection="rtl"
    this.langueLbl="الفرنسية"
  }
  
} 


filterCheque(data){
  // console.log(data);
  this.Cheques =this.tableDetailPaiements.filter((x) => x.modePaiement != 'Espèce' && x.nom.toLocaleLowerCase().includes(data.toLocaleLowerCase()));
  this.totalCommande();
}


  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  showDialogCheque(){
    this.displaycheque=true;
  }
  espece(){
   
    if (this.Paiement.modePaiement=='Espèce'){
      this.Paiement.reference=""; 
      this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
    }
  }
   save() {
    
    if (this.Paiement.id) {
      // alert(this.Paiement.id);
      this.dataTable="paiementFacture";
      this.Paiement.action = "Update";
      this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogDetailAvoir = false;
            this.displayDialogHistorique = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            this.filter(this.nom, 'nom');
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
      if (!this.idClient ){
        alert("Vous avez oublier le CLient !")
      }else{
        this.dataTable="paiementFacture";
        this.Paiement.action = "Insert";
        this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.Paiement.idClient = this.idClient;
        this.data
          .donnee(this.dataTable,this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.displayDialogDetailBon = false;
              this.displayDialogDetailAvoir = false;
              this.displayDialogHistorique = false;
              this.vente();
              this.venteDetail();
              this.venteRecap();
              this.paiementDetail();
              this.filter(this.nom, 'nom');
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.dataTable="paiementFacture";
      this.Paiement.action = "Delete";
      this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = id;
      this.data
        .donnee(this.dataTable,this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            
            // this.paiementRecap();
            // this.venteRecap();
            // this.paiementDetailClient();
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogDetailAvoir = false;
            this.displayDialogHistorique = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            alert(this.nom);
            this.filter(this.nom, 'nom');
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  printSituation(): void {
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-table").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }
  exportExcelClient(): void {
    let fileName = "Situation des paiements du Client " + this.nom +  ".xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-client");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation du Client : "+ this.nom ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        this.nom
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  exportExcelClients(): void {
    let fileName = "Situation des paiements.xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-clients");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation " ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        'Situation Paiment'
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  cloture(idPaiement,mode) {
    // alert(idPaiement+" | "+mode);

      this.blockedDocument=true;
      this.dataTable="paiementFacture";
      this.Paiement.action = "UpdateRegle";
      this.Paiement.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = idPaiement;
      this.Paiement.regle = mode;
      this.data
        .donnee(this.dataTable,this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            // this.displaycheque = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            this.filterCheque(this.nomClient);
          } else {
            alert(res.message);
          }
        });
  }
  newDonnee: boolean;
  showDialog() {
    this.filterPaiement();
    this.displayDialog = true;
    this.newDonnee = true;
    this.Vente = {};
  }
  showDialogToAdd() {
    this.filterPaiement();
    this.showButon = false;
    this.showButonSauvgarder = false;
    // this.newDonnee = true;
    this.Paiement={};
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    this.Vente = {};
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateEcheance = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateCheque = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.idClient=this.idClient;
    this.displayDialog = true;
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeVente.idPaiement) {
      this.tableCommandeVentes.push(data);

      // var index = this.tableCommandeVentes.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeVente = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeVentes.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeVentes[this.index].idClient = data.idClient;
    this.tableCommandeVentes[this.index].dateReglement = data.dateCommande;
    this.tableCommandeVentes[this.index].dateCheque = data.dateCheque;
    this.tableCommandeVentes[this.index].montant = data.montant;
    this.tableCommandeVentes[this.index].modePaiement = data.modePaiement;
    this.tableCommandeVentes[this.index].reference = data.reference;
    this.tableCommandeVentes[this.index].regle = data.regle;
    // this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeVente = {};
    // this.tableCommandeVente.idClient = this.idClient;
    // this.tableCommandeVente.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeVente = {};
  }

  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }

  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.Paiement.modePaiement = rechercheModePaiement.modePaiement;
  }

  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }
  totalBonDetail(){
    let totalMontant = 0;
    for (let data of this.tableCommandeDetail) {
      totalMontant += data.total;
    }
    this.totaldetailBon = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idPaiement == idPaiement);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +=
        "('idVente','" +
        data.idPaiement +
        "','" +
        data.quantite +
        "','" +
        data.prixVente +
        "','" +
        data.prixVenteDetail +
        "','" +
        data.prixVenteGros +
        "','" +
        data.prixVente +
        "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idClient);
  }

  onRowSelect(event) {
    // alert(event.data.idClient)
    this.displayDialogHistorique = true;

    // console.log();
    this.idClient = event.data.idClient;
    this.nom = event.data.nom;
    this.nomAr = event.data.nomAr;
    this.montant = event.data.montant - event.data.remise;
    // alert(this.idClient)
    // this.Vente.dateCommande = event.data.dateCommande;
    // this.Vente.idClient = event.data.idClient;
    this.initialiser();
    this.filterPaiement();
    let recherche = this.tableDetailsVentes.filter((x) => x.idClient == event.data.idClient);
    this.tableDetailsVentesRequette = recherche;
    let cumul=0;
    console.log(this.tableDetailsVentes);
    if (this.tableDetailsVentesRequette.length!=undefined){
      this.tableDetailsVentesRequette.forEach((row ,index )=>{
        if(row.mode=='Avoir' || row.mode=='Avance'){
          if (row.regle=='o' || row.mode=='Avoir'){
              cumul += -row.avance*1;
          }else{
            cumul += 0;
          }          
        }else if(row.mode=='Bon'){
          cumul += row.montant*1.2 -row.remise*1;
        } 
        // console.log(cumul +"+"+ row.montant+"+" + row.avance );
        this.tableDetailsVentesRequette[index].cumul = cumul;

        })
    }
    // console.log(this.tableDetailPaiements)
    // this.tableCommandeVentes = recherche;
    // console.log(this.tableDetailPaiements);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.newDonnee = false;
    // this.Vente = this.cloneDonnee(event.data);
    // alert(this.tableCommandeVente.idClient);
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }

  onRowSelectDetailBon(event) {
    // alert(event.data.mode);
    
    if (event.data.mode=='Bon'){
      this.displayDialogDetailBon = true;
      this.Vente.remise = event.data.remise;
      let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      // console.log(recherche);
      this.totalBonDetail();
    }else  if (event.data.mode=='Avoir'){
      this.displayDialogDetailAvoir = true;
      let recherche = this.tableDetailAvoirs.filter((x) => x.idCommande == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      // console.log(recherche);
      this.totalBonDetail();
    }else if(event.data.mode=='Avance'){
      this.displayDialog=true;
      let rechercheP = this.tableClientPaiements.filter((x) => 
        x.idClient==this.idClient  && x.id==event.data.id      
      ); 
      this.tableClientPaiementss = rechercheP;
      this.onRowSelectCommandeDetail(event);

    }
  }
  onRowSelectCommandeDetail(event) {
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert( event.data.idClient);
    this.Paiement.idClient = event.data.idClient;
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.tableCommandeVente.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.tableCommandeVente.dateCheque = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    this.tableDetailsVentesRequette = [];
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    // this.Paiement.modePaiement="";
    // this.tableCommandeVente = {};
    this.Paiement.dateCommande = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateEcheance = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateReglement = new Date()
    .toISOString()
    .split("T")[0];
    this.Paiement.dateCheque = new Date()
    .toISOString()
    .split("T")[0];
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.tableCommandeVente.idPaiement = null;
    // this.tableCommandeVente.quantite = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVenteDetail = "";
    // this.tableCommandeVente.prixVenteGros = "";
    this.showButon = false;
  }
  closeCheque(){
    this.displaycheque=false;
  }
  closeHistorique(){
    this.displayDialogHistorique=false;
  }
  closeDetailBon(){
    this.displayDialogDetailBon=false;
  }
  closeDetailAvoir(){
    this.displayDialogDetailAvoir=false;
  }
}